import { render, staticRenderFns } from "./SurveyCreatorVue.vue?vue&type=template&id=1d544bfc&scoped=true"
import script from "./SurveyCreatorVue.vue?vue&type=script&lang=js"
export * from "./SurveyCreatorVue.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d544bfc",
  null
  
)

export default component.exports