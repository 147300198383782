<template>
  <div id="tq-data-chart" style="border-top: 1px solid rgb(221, 221, 221);">
    <div class='resizable'>
      <div ref="chartContainer" class="chart-container">
        <highcharts ref="chart" class="chart" :options="chartOptions" :updateArgs="updateArgs"></highcharts>
      </div>
      <div class="resize-handle top-left" v-on:mousedown="startResize"></div>
      <div class="resize-handle top-right" v-on:mousedown="startResize"></div>
      <div class="resize-handle bottom-left" v-on:mousedown="startResize"></div>
      <div class="resize-handle bottom-right" v-on:mousedown="startResize"></div>
    </div>
    <div>
      <md-dialog :md-active.sync="exportDialogVisible">
        <md-toolbar md-elevation="0" class="md-title md-primary">{{ $t('actions.export') }}</md-toolbar>
        <md-dialog-content style="overflow: auto">
          <div class="md-subheading">{{ $t('labels.filetype') }}</div>
          <div style="margin-left: 16px;">
            <div style="display: flex; flex-direction: column;">
              <div style="display: flex; flex-direction: column; align-items:flex-start">
                <md-radio v-model="exportSettings.exportType" value="png" style="margin-bottom: 0;">PNG</md-radio>
                <div v-if="exportSettings.exportType === 'png'" >
                </div>
              </div>
              <div style="display: flex; flex-direction: column; align-items:flex-start">
                <md-radio v-model="exportSettings.exportType" value="pdf" style="margin-bottom: 0;">PDF</md-radio>
                <div v-if="exportSettings.exportType === 'pdfg'" >
                </div>
              </div>
              <div style="display: flex; flex-direction: column; align-items:flex-start">
                <md-radio v-model="exportSettings.exportType" value="json" style="margin-bottom: 0;">JSON</md-radio>
                <div style="margin-left: 16px">
                  <md-checkbox v-if="exportSettings.exportType === 'json'" v-model="exportSettings.embedJsonInHtml" style="margin-bottom: 0;">Include HTML</md-checkbox>
                </div>
              </div>
              <div v-if="isImageToClipboardSupported" style="display: flex; flex-direction: column; align-items:flex-start">
                <md-radio v-model="exportSettings.exportType" value="clipboard-png" style="margin-bottom: 0;">{{ $t('labels.clipboard') }} (PNG)</md-radio>
              </div>
              <div style="display: flex; flex-direction: column; align-items:flex-start">
                <md-radio v-model="exportSettings.exportType" value="clipboard-json" style="margin-bottom: 0;">{{ $t('labels.clipboard') }} (JSON)</md-radio>
                <div style="margin-left: 16px">
                  <md-checkbox v-if="exportSettings.exportType === 'clipboard-json'" v-model="exportSettings.embedJsonInHtml" style="margin-bottom: 0;">Include HTML</md-checkbox>
                </div>
              </div>
            </div>
          </div>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button @click="exportDialogVisible = false">{{ $t('actions.cancel') }}</md-button>
          <md-button class="md-primary" @click="exportDialogVisible = false; startExport();">OK</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>

<script>

import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import drilldown from 'highcharts/modules/drilldown';
import bubble from 'highcharts/modules/accessibility';
import more from 'highcharts/highcharts-more';
import { getLocale } from '@/i18n';
import dateFormat from 'dateformat';
import saveAs from 'file-saver';
import api from '@/services/api';
import urlService from '@/services/url';
import error from '@/services/error';
import { svgToCanvas } from '@/services/svg2canvas'


exporting(Highcharts);
offlineExporting(Highcharts);
drilldown(Highcharts);
more(Highcharts);

export default {
  props: {
    enabled: Boolean
  },
  data () {
    return {
      changed: true,
      dataChanged: true,
      updateArgs: [true, true, {duration: 1000}],
      chartOptions: {
        title: {
          text: ''
        }

      },
      exportDialogVisible: false,
      exportSettings: {
        exportType: 'png',
        onlyVisible: 'true',
        csvDecimalSeparator: null,
        includeLink: false
      },
      resizeData: null,
      pointClickTimeoutId: null,
      isImageToClipboardSupported: false
    }
  },
  mounted() {
    Highcharts.setOptions({
      lang: {
        drillUpText: "◁ Back"
      },
      chart: {
        events: {
          drilldown: e => {
            return e.points !== false || e.originalEvent == null;
          },
        },

      },
      /* tooltip: {
           formatter: function () {
             let baseValue = this.point.baseValue;
             var s = '<b>'+ this.x + (baseValue == null ? '' : ' (N=' + baseValue + ')') + '</b>';
             let y = Highcharts.numberFormat(this.y, 2);
             s += '<br/><span style="color:' + this.color + '">\u25CF</span> ' + this.series.name + ': ' + y;
             return s;
          }
        } */
    });
    if (this.enabled) {
      this.getData(true, false);
    }
    // ClipboardItem is not available in Firefox, therefore we cannot copy images to the clipboard.
    this.isImageToClipboardSupported = (typeof ClipboardItem !== 'undefined');
  },
  updated() {
    this.$refs.chart.chart.reflow();
  },
  beforeDestroy () {
    this.$store.commit('setAnalysisSettingsChartDirty', false);
  },
  watch: {
    '$store.state.analysisSettingsChartDirty': function(analysisSettingsChartDirty) {
      this.changed = analysisSettingsChartDirty || this.changed;
      if (this.enabled && !this.$store.state.analysisSettingsTabulationDirty) {
        urlService.updateAnalysisUrl('chart', true);
        this.getData(false, false);
      }
    },
    '$store.state.analysisSettingsTabulationDirty': function(analysisSettingsTabulationDirty) {
      if (analysisSettingsTabulationDirty) {
        this.dataChanged = true;
        this.changed = true;
      }
    },
    '$store.state.filterValuesChanged': function(filterValuesChanged) {
      if (filterValuesChanged) {
        this.dataChanged = true;
        this.changed = true;
      }
    },
    enabled(enabled) {
      if (enabled && this.changed) {
        this.getData(true, false);
      }
    },
    title (newValue) {
      this.chartOptions.title.text = newValue
    },
    points (newValue) {
      this.chartOptions.series[0].data = newValue
    },
    animationDuration (newValue) {
      this.updateArgs[2].duration = Number(newValue)
    }
  },
  methods: {
    refresh(noCache) {
      if (this.enabled) {
        this.getData(true, noCache);
      } else {
        this.changed = true;
      }
    },
    startResize(e) {
      e.preventDefault();
      let element = document.querySelector('.chart-container');
      let style = window.getComputedStyle(element);
      this.resizeData = {
        resizeHandleElement: e.target,
        original_width: parseFloat(style.width.replace('px', '')),
        original_height: parseFloat(style.height.replace('px', '')),
        original_x: parseFloat(style.left.replace('px', '')),
        original_y: parseFloat(style.top.replace('px', '')),
        original_mouse_x: e.pageX,
        original_mouse_y: e.pageY,
      };
      window.addEventListener('mousemove', this.resize);
      window.addEventListener('mouseup', this.stopResize);
    },
    resize(e) {
      if ((e.buttons & 1) === 0) {
        this.stopResize();
        return;
      }
      let container = document.querySelector('#tq-data-chart');

      let resizeData = this.resizeData;
      if (resizeData) {
        let resizeHandle = resizeData.resizeHandleElement;
        let top = resizeHandle.classList.contains('top-right') || resizeHandle.classList.contains('top-left');
        let left = resizeHandle.classList.contains('top-left') || resizeHandle.classList.contains('bottom-left');

        let containerRect = container.getBoundingClientRect();
        let containerX = e.pageX - containerRect.x;
        let containerY = e.pageY - containerRect.y;

        if (!left) {
          containerX = containerRect.width - containerX;
        }
        if (!top) {
          containerY = containerRect.height - containerY;
        }
        let widthPercent = Math.min(100, Math.max(10, Math.round(100 * (1 - 2 * containerX / containerRect.width))));
        // Limiting height to 99% prevents potential engless growth of the chart in y direction. Not sure why this happens.
        let heightPercent = Math.min(100, Math.max(10, Math.round(100 * (1 - 2 * containerY / containerRect.height))));
        this.setChartSize(widthPercent + '%', heightPercent + '%');
        let chart = this.$refs.chart.chart;
        window.requestAnimationFrame(function() {
          chart.setSize(null, null, false);
        });
        // Set size to settings
        let analysisSettings = this.$store.state.analysisSettings;
        let settingsChartOptions = analysisSettings.chart_options;
        if (settingsChartOptions) {
          if (settingsChartOptions.chart == null) {
            settingsChartOptions.chart = {};
          }
          settingsChartOptions.chart.width = widthPercent + '%';
          settingsChartOptions.chart.height = heightPercent + '%';
        }
      }
    },
    stopResize() {
      urlService.updateAnalysisUrl('chart', true);
      window.removeEventListener('mousemove', this.resize)
      window.removeEventListener('mouseup', this.stopResize);
    },
    perseChartDimension(value) {
      value = value == null ? '100%' : ('' + value).trim();
      let floatValue = parseFloat(value);
      if (isNaN(floatValue)) {
        value = '100%';
      } else if (value.endsWith('%')) {
        floatValue = Math.min(100, Math.max(10, floatValue));
        value = floatValue + '%';
      } else {
        value = floatValue + 'px';
      }
      return value;
    },
    setChartSize(widthValue, heightValue) {
      let resizableElement = document.querySelector('.resizable');
      resizableElement.style.width = this.perseChartDimension(widthValue);
      resizableElement.style.height = this.perseChartDimension(heightValue);
    },
    exportData() {
      this.exportDialogVisible = true;
    },
    async startExport() {
      try {
        let exportType = this.exportSettings.exportType;
        let chartContainer = this.$refs.chartContainer;
        let chart = this.$refs.chart.chart;
        let filename =  'Chart_' + this.$store.state.view.name + '_' + dateFormat(Date.now(), "yyyymmdd");
        if (exportType === 'json' || exportType === 'clipboard-json') {
          let chartOptions = this.chartOptions;
          // Remove height property for stringifycation and put it back afterwards.
          let chartHeight;
          let chartWidth;
          if (chartOptions.chart != null) {
            chartHeight = chartOptions.chart.height;
            chartWidth = chartOptions.chart.width;
            delete chartOptions.chart.height;
            delete chartOptions.chart.width;
          }
          // Add exporting options, so the print/export menu will show in exported chart. This will be removed afterwards.
          chartOptions.exporting = {
            buttons: {
              contextButton: {
                menuItems: ["viewFullscreen", "printChart","separator","downloadPNG","downloadSVG","downloadPDF","downloadJPEG"]
              }
            },
          };

          if (chartOptions.credits == null) {
            chartOptions.credits = {};
          }
          chartOptions.credits.enabled = true;

          if (chartOptions.credits._isLink) {
            let link = (await api.call('saveLink', {
              linkType: 'ANALYSIS_SETTINGS_LINK',
              data: {
                analysisView: 'chart',
                path: this.$route.fullPath
              }
            })).data;
            
            let linkId = link.id;
            let protocol = location.protocol;
            let port = location.port;
            let host = window.location.hostname;
            let href = protocol + '//' + host + (port && port !== 80 && port !== 443 ? ':' + port : '')  +  '/' + linkId;
            chartOptions.credits.href = 'javascript:void(window.open("' + href + '", "woenenn"))';
            // set link color
            if (chartOptions.credits.style == null) {
              chartOptions.credits.style = {};
            }
            chartOptions.credits.style.color = '#007bff';
          }

          let optionsJson = JSON.stringify(this.chartOptions, null, 2);
          if (this.exportSettings.embedJsonInHtml) {
            let chartId = Math.random().toString(16).slice(2, 8); // random six digit hexadecimal id
            let analysisSettings = this.$store.state.analysisSettings;
            let settingsChartOptions = (analysisSettings && analysisSettings.chart_options) || {};
            let exportCssWidth = settingsChartOptions._other && settingsChartOptions._other.exportCssWidth;
            let exportCssHeight = settingsChartOptions._other && settingsChartOptions._other.exportCssHeight;
            let exportCssFloat = settingsChartOptions._other && settingsChartOptions._other.exportCssFloat;
            let widthHeightFloatStyle = '';
            if (exportCssWidth != null && exportCssWidth !== '') {
              widthHeightFloatStyle += 'width:' + exportCssWidth + '; ';
            }
            if (exportCssHeight != null && exportCssHeight !== '') {
              widthHeightFloatStyle += 'height:' + exportCssHeight + '; ';
            }
            if (exportCssFloat != null && exportCssFloat !== '' && exportCssFloat !== 'none') {
              widthHeightFloatStyle += 'float:' + exportCssFloat + '; ';
            }
            widthHeightFloatStyle = widthHeightFloatStyle.trim();

            let divStr = '<div id="' + chartId + '"';
            if (widthHeightFloatStyle !== '') {
              divStr += ' style="' + widthHeightFloatStyle + '"';
            }
            divStr += '><\/div>';

            let scriptStr = '<script>\n'
                // Set global Highcharts options
                + 'Highcharts.setOptions({"lang": {"drillUpText": "◁ Back"}});\n'
                // Create chart with options
                + 'Highcharts.chart("' + chartId + '",' + optionsJson + ');\n'
                + '<\/script>';

            // Set optionsJson as html
            optionsJson = divStr + '\n' + scriptStr;

          }
          // Put back charHeight/chartWidth and remove exporting options
          if (chartHeight != null) {
            chartOptions.chart.height = chartHeight;
          }
          if (chartWidth != null) {
            chartOptions.chart.width = chartWidth;
          }
          delete chartOptions.exporting;
          if (chartOptions.credits != null) {
            chartOptions.credits.enabled = false;
          }
          if (exportType === 'clipboard-json') {
            navigator.clipboard.writeText(optionsJson).then(function() {}, function(err) {
              console.error('Could not copy text: ', err);
            });
          } else {
            // Save JSON
            let blob = new Blob([optionsJson], {type: "text/plain;charset=utf-8"});
            saveAs(blob, filename + '.json');
          }
        } else if (exportType === 'clipboard-png') {
          let svg = this.$refs.chartContainer.getElementsByTagName('svg')[0];
          let exportingGroup = svg.getElementsByClassName('highcharts-exporting-group')[0];
          if (exportingGroup != null) {
            exportingGroup.setAttribute('visibility', 'hidden');
          }
          let drillupButton = svg.getElementsByClassName('highcharts-drillup-button')[0];
          if (drillupButton != null) {
            drillupButton.setAttribute('visibility', 'hidden');
          }
          let svgStr = new XMLSerializer().serializeToString(svg);
          let canvas = await svgToCanvas(svgStr, svg.getAttribute('width'), svg.getAttribute('height'));
          try {
            let blob = await new Promise(function (resolved) {canvas.toBlob((blob) => resolved(blob), 'image/png')});
            if (typeof ClipboardItem === 'undefined') {
              let dataUrl = canvas.toDataURL();
              let img = document.createElement('img');
              img.src = dataUrl;
              document.body.appendChild(img);
              var range = document.createRange();
              range.selectNode(img);
              var selection = window.getSelection();
              selection.removeAllRanges();
              selection.addRange(range);
              document.execCommand('copy');
              img.remove();
              //navigator.clipboard.setImageData(await blob.arrayBuffer(), 'png');
            } else {
              await navigator.clipboard.write([
                new ClipboardItem({
                  'image/png': blob,
                  //  'text/plain': new Blob([svgStr], {type: 'text/plain'})
                }),
              ]);
            }
          } catch (error) {
            console.error(error);
          } finally {
            if (exportingGroup != null) {
              exportingGroup.removeAttribute('visibility');
            }
            if (drillupButton != null) {
              drillupButton.removeAttribute('visibility');
            }
          }
        } else {
          let exportOptions = {
            filename: filename,
            sourceHeight: chartContainer.clientHeight,
            sourceWidth: chartContainer.clientWidth,
            fallbackToExportServer: false
          };
          if (exportType === 'pdf') {
            exportOptions.type = 'application/pdf';
          } else if (exportType === 'png') {
            exportOptions.type = 'image/png';
          }
          let chartOptions= {
            credits: this.chartOptions.credits || {}
          };
          chartOptions.credits.enabled = true;
          chart.exportChartLocal(exportOptions, chartOptions);
        }
      } catch(e) {
        error.runtimeError(e)
      }
    },
    getData: async function(forceReload, noCache) {
      try {
        this.changed = false;
        this.$store.commit('setAnalysisSettingsChartDirty', false);
        this.$store.dispatch('setLoading', true);
        if (forceReload || noCache || this.dataChanged) {
          this.dataChanged = false;
          await this.$store.dispatch('getSourceViewPivotResults', {noCache: noCache})

          let analysisSettings = this.$store.state.analysisSettings;
          let queryResults = this.$store.state.pivotResults;
          this.updateData(queryResults, analysisSettings);
          this.$store.dispatch('setLoading', false);
          this.$store.commit('setAnalysisSettingsTabulationDirty', false);
        } else {
          let analysisSettings = this.$store.state.analysisSettings;
          let queryResults = this.$store.state.pivotResults;
          this.updateData(queryResults, analysisSettings);
          this.$store.dispatch('setLoading', false);
        }
      } catch(e) {
        error.runtimeError(e)
      }
    },
    updateData(queryResult, analysisSettings) {
      let chartElement = this.$refs.chart;
      if (chartElement && chartElement.chart) {
        // Data can become inconsistent when new data is set and drilldown is used.
        // Therefore we destroy and rebuild the chart to ensure data is clean.
        chartElement.chart.destroy();
        chartElement.chart = new Highcharts.Chart({chart: {renderTo: this.$refs.chartContainer}});
      }
      let settingsChartOptions = (analysisSettings && analysisSettings.chart_options) || {};
      let chartOptions = this.queryResultToChartOptions(queryResult, analysisSettings);
      delete settingsChartOptions.series; //ToDo: Temporary solution
      this.deepMergeOptions(chartOptions, settingsChartOptions);
      if (chartOptions.title == null || chartOptions.title.text == null) {
        chartOptions.title = chartOptions.title || {};
        chartOptions.title.text = '';
      }

      // Make sure width and height are set to null. They can have non-null values from old settings.
      if (!chartOptions.chart) {
        chartOptions.chart = {};
      }
      this.setChartSize(chartOptions.chart.width, chartOptions.chart.height);
      chartOptions.chart.width = null;
      chartOptions.chart.height = null;

      // Replace placeholders with values.
      this.processPlaceholders(chartOptions)

      //console.log(JSON.stringify(chartOptions, null, 2))

      this.chartOptions = chartOptions;
    },
    pointClick(e) {
      // Start timeout to trigger drillown. If another click event occurs before timeout has finished,
      // clear the timeout and show datasets for data point.
      if (this.pointClickTimeoutId == null) {
        this.pointClickTimeoutId = setTimeout(() => {
          // ddDupes must be cleared, otherwise drilldown will not work.
          delete this.$refs.chart.chart.ddDupes;
          e.point.doDrilldown();
          this.pointClickTimeoutId = null;
        }, 300);
      } else {
        clearTimeout(this.pointClickTimeoutId);
        this.pointClickTimeoutId = null;
        let id = e.point.id;
        let rowIndex = id.match(/r(\d*)/)[1];
        let colIndex = id.match(/c(\d*)/)[1];

        let queryResult = this.$store.state.pivotResults;
        let filterCriteria = [];
        // Collect filter criteria for column variables
        let columnVars = queryResult.queryData.tabulation_columns;
        let column = queryResult.columns[colIndex];
        if (!Array.isArray(column)) {
          column = [column]
        }
        for (let i = 0; i < column.length; i++) {
          // Column is not a Total column or a row variable column
          let columnVarName = columnVars[i];
          let colValue = column[i];
          // Remove base value (for example "(N=205)") from end of header name if present.
          colValue = this.removeBaseValueFromColName(colValue);
          if (colValue !== '__Total' && colValue !== '') {
            filterCriteria.push({name: columnVarName, value: colValue});
          }
        }

        let rowVars = queryResult.queryData.tabulation_rows;
        let row = queryResult.rows[rowIndex];
        if (!Array.isArray(row)) {
          row = [row];
        }
        for (let i = 0; i < row.length; i++) {
          // Column is not a Total column or a row variable column
          let rowVarName = rowVars[i];
          let rowValue = row[i];
          // Remove base value (for example "(N=205)") from end of header name if present.
          rowValue = this.removeBaseValueFromColName(rowValue);
          if (rowValue !== '__Total' && rowValue !== '') {
            filterCriteria.push({name: rowVarName, value: rowValue});
          }
        }

        let cellFilter;
        if (filterCriteria.length > 0) {
          cellFilter = {};
          filterCriteria.forEach(({name, value}) => {
            cellFilter[name.replace('.fractions.', ':')] = value;
          });
        }

        //console.log("cellFilter", cellFilter);
        this.$store.dispatch('setCellFilter', cellFilter);
        this.$store.dispatch('setAnalysisView', 'datasets');
        return false;
      }
    },
    removeBaseValueFromColName(name) {
      // Labels for the last column var have the base appended // Labels for the last column var have the base appended (for example "Diesel (N=421)". This removes
      // the base and the surrounding brackets and leading whitespace.
      return name.replace(/\s*\(N=\d+\)/, '');
    },
    getBaseValueFromColName(name) {
      // Extreact the base value from a column name if available (for example 421 for "Diesel (N=421)")
      let baseValMatch = name.match(/\(N=(\d+)\)/);
      if (baseValMatch != null) {
        return baseValMatch[1];
      } else {
        return null;
      }
    },
    formatNumber(number, decimalPlaces) {
      if (number === '') {
        return null;
      } else {
        number = parseFloat(number);
        return (number + (number >= 0 ? Number.EPSILON : -Number.EPSILON)).toFixed(decimalPlaces || 0);
      }
    },
    queryResultToChartOptions(queryResult, analysisSettings) {
      let variablesNameMap = {};
      for (let variable of this.$store.state.view.variables) {
        variablesNameMap[variable.name] = variable;
      }
      let settingsChartOptions = (analysisSettings && analysisSettings.chart_options) || {};


      // settingsChartOptions.colors is not used anymore, but it can exist in bookmarks. If defined, use the colors
      // to override chart_series colors
      let colors = settingsChartOptions.colors ||
          ['#0072C0', '#7DD259', '#F3AB55', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'];
      // Chart series. These define a color and the chart type for each series.
      if (!settingsChartOptions.chart_series) {
        settingsChartOptions.chart_series =
            colors.map(color => {
              return {color: color, type: 'inherit'}
            });
      }
      let settingsModel = this.$store.state.analysisSettingsModel;
      let optionsModel = settingsModel.find(el => el.name === 'chart_options');
      let colorsOptionsModel = optionsModel.items.find(el => el.name === 'colors');
      let seriesOptionsModel = optionsModel.items.find(el => el.name === 'series');

      if (!seriesOptionsModel) {
        let defaultColors = (colorsOptionsModel && colorsOptionsModel.colors) || colors;
        seriesOptionsModel = {
          name: 'series',
          label: 'Series',
          type: 'group',
          items: [{
            name: 'chartSeries',
            type: 'series_array',
            settingsKey: 'chart_series',
            default: defaultColors.map(color => {return {color: color, type: 'inherit'}})
          }]
        };
        let insertIndex = optionsModel.items.findIndex(el => el.name === 'colors');
        if (insertIndex < 0) {
          insertIndex = optionsModel.items.length;
        }
        optionsModel.items.splice(insertIndex, 0, seriesOptionsModel);
      }
      if (!settingsChartOptions.chart_series) {
        settingsChartOptions.chart_series = colors.map(color => {
          return {color: color, type: 'inherit'};
        });
      }
      // Handle old colors options
      if (settingsChartOptions.colors) {
        delete settingsChartOptions.colors;
      }
      if (colorsOptionsModel) {
        optionsModel.items.splice(optionsModel.items.findIndex(el => el === colorsOptionsModel), 1);
      }


      let ratios = queryResult.ratios;
      let ratiosModel = settingsModel.find(el => el.name === 'ratios');


      let fontSize100 = parseInt((settingsChartOptions._other && settingsChartOptions._other.fontSize) || 12);
      if (isNaN(fontSize100)) {
        fontSize100 = 12;
      }

      let fontSize120 = Math.round(fontSize100 * 1.2);
      let fontSize90 = Math.round(fontSize100 * 0.9);
      let fontSize80 = Math.round(fontSize100 * 0.8);

      let chartOptions = {
        caption: {
          style: {
            fontSize: fontSize120 + 'px',
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
          }
        },
        chart: {
          style: {
            fontSize: fontSize100 + 'px',
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
          }
        },
        credits: {
          enabled: false,
          style: {
            fontSize: fontSize100 + 'px',
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
          }
        },
        drilldown: {
          //allowPointDrilldown: false,
          activeAxisLabelStyle: {
            textDecoration: 'none', // Remove underline from drilldown links
          }
        },
        legend: {
          itemStyle: {
            fontSize: fontSize100 + 'px',
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
          }
        },
        plotOptions: {
          series: {
            dataGrouping: {
              enabled: false
            },
            dataLabels: {
              format: '{point.yRounded}',
              style: {
                fontSize: fontSize90 + 'px',
                fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
              }
            }
          }
        },
        subtitle: {
          style: {
            fontSize: fontSize100 + 'px',
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
          }
        },
        title: {
          style: {
            fontSize: fontSize120 + 'px',
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
          }
        },
        tooltip: {
          style: {
            fontSize: fontSize100 + 'px',
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
          }
        },
        xAxis: {
          labels: {
            style: {
              fontSize: fontSize90 + 'px',
              fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
            }
          },
          title: {
            style: {
              fontSize: fontSize100 + 'px',
              fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
            }
          }
        },
        yAxis: {
          labels: {
            style: {
              fontSize: fontSize90 + 'px',
              fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
            }
          },
          title: {
            style: {
              fontSize: fontSize100 + 'px',
              fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
            }
          }
        }
      };

      let tooltipHeaderFontSize = fontSize80;


      // option to define if grouping is done on second variable (default) or on rations. Values are "default" and "ratios"
      let groupBy = settingsChartOptions._other && settingsChartOptions._other.groupBy;
      let ratiosAsSeries = groupBy === 'invRatios'; // ToDO: Experiemental
      let groupByRatios = ratiosAsSeries || groupBy === 'ratios';

      let showTotal = settingsChartOptions._other && settingsChartOptions._other.showTotal;

      // Handle sorting. In the chart options model we changed the type for _sortData from "boolean" to "list", so we have to convert boolean values if
      let sortDir = settingsChartOptions.plotOptions && settingsChartOptions.plotOptions.series && settingsChartOptions.plotOptions.series._sortDir;
      let sortData = settingsChartOptions.plotOptions && settingsChartOptions.plotOptions.series && settingsChartOptions.plotOptions.series._sortData;
      if (sortData != null) {
        if (sortDir != null) {
          settingsChartOptions.plotOptions.series._sortDir = sortData === true ? 'desc' : 'none';
          delete settingsChartOptions.plotOptions.series._sortData;
        }
        sortDir = sortData === true ? 'desc' : 'none';
      }
      if (sortDir == null) {
        // Fallback
        sortDir = "none";
      }

      let colVarCount = queryResult.queryData.tabulation_columns.length;
      let rowVarCount = queryResult.queryData.tabulation_rows.length;
      let varCount = colVarCount + rowVarCount;

      let resultCols = queryResult.columns;

      // console.log(JSON.stringify(rootColNodes, null, 2))

      if (chartOptions.xAxis) {
        chartOptions.xAxis.type = 'category';
        let rowVar = variablesNameMap[queryResult.queryData.tabulation_columns[0]];
        chartOptions.xAxis.title = chartOptions.xAxis.title || {};
        chartOptions.xAxis.title.text = rowVar ? rowVar.label : 'No column variable selected';
      } else {
        chartOptions.xAxis = {type: 'category'};
      }

      if (chartOptions.yAxis == null) {
        chartOptions.yAxis = {};
      }
      let yAxisTitle;
      if (ratios.length > 1 && groupByRatios) {
        if (ratios.findIndex(ratio => ratio.name !== ratios[0].name) === -1) {
          // All ratio names are identical
          yAxisTitle = this.getRatioLabel(ratiosModel, ratios[0]);
        } else {
          // Ratio names are different, set no label for y axis
          yAxisTitle = '';
        }
      } else {
        yAxisTitle = this.getRatioLabel(ratiosModel, ratios[0]);
      }
      chartOptions.yAxis.title = chartOptions.yAxis.title || {};
      chartOptions.yAxis.title.text = yAxisTitle;

      let resultRows = queryResult.rows;

      let ratioDatas = [];

      let useRatioVariableAsName = settingsChartOptions._other && settingsChartOptions._other.ratioVariableAsName === true;

      let hideEmptyValues =  settingsChartOptions.plotOptions && settingsChartOptions.plotOptions.series._hideEmptyValues === true;
      let hideZeroValues = true;
      let hideDrilldownZeroValues = true;

      // If noDrilldown is set to true, there will be no drilldown in any case.
      let noDrilldown = settingsChartOptions._other && settingsChartOptions._other.noDrilldown === true;

      let ratioCount = (groupByRatios ? ratios.length : ratios.length === 0 ? 0 : 1);

      for (let ratioIndex = 0; ratioIndex < ratioCount; ratioIndex++) {
        let ratio = ratios[ratioIndex];

        let ratioValues = queryResult[ratio.id];
        if (ratioValues != null && ratioValues.length > 0) {
          let idPrefix = '$' + ratioIndex;

          // build column tree model. Each node contains its index, name and an array of child nodes.
          let colNodeStack = [];
          let rootColNodes = [];
          let leafColNodes = [];
          for (let colIndex = resultCols.length - 1; colIndex >= 0; colIndex--) {
            let col = resultCols[colIndex];
            let colLevel;
            let colName;
            let baseValue;
            if (colVarCount === 1) {
              // column is a string
              let isTotal = col.startsWith('__Total');
              if (showTotal || !isTotal) {
                colName = isTotal ? 'Total' : this.removeBaseValueFromColName(col);
                baseValue = this.getBaseValueFromColName(col);
                colLevel = 0;
              }
            } else if (colVarCount > 1) {
              // Column is an array of strings. Iterate from last to first, using the first
              // non-total entry as the row name.
              for (let i = col.length - 1; i >= 0; i--) {
                let isTotal = col[i].startsWith('__Total');
                if ((showTotal && i === 0) || !isTotal) {
                  colName = isTotal ? 'Total' : this.removeBaseValueFromColName(col[i]);
                  colLevel = i;
                  baseValue = this.getBaseValueFromColName(col[i]);
                  if (!isTotal) {
                    break;
                  }
                }
              }
            }
            if (colName != null) {
              let colNode = {
                id: idPrefix + "c" + colIndex,
                name: colName,
                colIndex: colIndex,
                varLevel: colLevel,
                baseValue: baseValue,
                children: []
              };
              if (colLevel === colVarCount - 1) {
                leafColNodes.unshift(colNode);
              }
              if (colLevel === 0) {
                rootColNodes.unshift(colNode);
              } else {
                colNodeStack[colLevel - 1].children.unshift(colNode);
              }
              colNodeStack[colLevel] = colNode;
            }
          }

          let rootRowNodes = [];

          if (ratiosAsSeries) {
            // ToDO. Experimental
            let rowIndex = resultRows.length - 1;
            let rowNode = {
              id: 'r' + rowIndex,
              name: 'Total',
              rowIndex: rowIndex,
              rowLevel: 0,
              children: []
            };
            rootRowNodes.push(rowNode);
          } else {
            let rowNodeStack = [];
            for (let rowIndex = resultRows.length - 1; rowIndex >= 0; rowIndex--) {
              let row = resultRows[rowIndex];
              let rowLevel;
              let rowName = null;
              if (rowVarCount === 1) {
                // row is a string
                let isTotal = row.startsWith('__Total');
                if (!isTotal) {
                  rowName = isTotal ? 'Total' : row;
                  rowLevel = 0;
                }
              } else {
                // row is an array of strings. Iterate from last to first, using the first
                // non-total entry as the row name.
                for (let i = row.length - 1; i >= 0; i--) {
                  let isTotal = row[i].startsWith('__Total');
                  if (!isTotal) {
                    rowName = isTotal ? 'Total' : row[i];
                    rowLevel = i;
                    if (!isTotal) {
                      break;
                    }
                  }
                }
              }
              if (rowName != null) {
                let rowNode = {
                  id: 'r' + rowIndex,
                  name: rowName,
                  rowIndex: rowIndex,
                  rowLevel: rowLevel,
                  children: []
                };
                if (rowLevel === 0) {
                  rootRowNodes.unshift(rowNode);
                } else {
                  if (rowNodeStack[rowLevel - 1] != null)
                    rowNodeStack[rowLevel - 1].children.unshift(rowNode);
                }
                rowNodeStack[rowLevel] = rowNode;
              }
            }
          }

          // create data tree model by adding all root row nodes to each leaf column node. The column nodes are already in a tree structure if multiple colum variables exist.
          let copyRowNode = function (rowNode, colIndex) {
            let newChildNodes = rowNode.children.slice();
            let newNode = Object.assign({}, rowNode);
            newNode = Object.assign(newNode, {
              id: idPrefix + 'c' + colIndex + 'r' + rowNode.rowIndex,
              colIndex: colIndex,
              varLevel: rowNode.rowLevel + colVarCount,
            });
            for (let childIndex = 0; childIndex < newChildNodes.length; childIndex++) {
              newChildNodes[childIndex] = copyRowNode(newChildNodes[childIndex], colIndex);
            }
            newNode.children = newChildNodes;
            return newNode;
          }

          // Copy root row nodes into leaf column nodes //ToDo: Brauchen wir das, ist es hier richtig?
          for (let leafColNode of leafColNodes) {
            let colNodeIndex = leafColNode.colIndex;
            for (let rootRowNode of rootRowNodes) {
              leafColNode.children.push(copyRowNode(rootRowNode, colNodeIndex));
            }
          }

          // At this point we have a combined tree structure of column nodes and row nodes. The root nodes are in rootColNodes.

          // Set y  and yRounded value for all nodes in tree.
          // Number of decimal places for rounding
          let decimalPlaces = Number.parseInt(analysisSettings.ratios[0].decimal_places);
          let setNodeValue = node => {
            let colIndex = node.colIndex;
            let rowIndex = node.rowIndex;
            if (rowIndex == null) {
              rowIndex = ratioValues.length - 1;
            }
            let value = ratioValues[rowIndex][colIndex];
            node.y = value;
            node.yRounded = value === '' ? null : this.formatNumber(value, decimalPlaces);
            if (node.children) {
              node.children.forEach(childNode => setNodeValue(childNode));
            }
          }
          rootColNodes.forEach(node => setNodeValue(node));


          if (hideEmptyValues) {
            // remove all empty nodes
            let removeEmptyNodes = nodeArray => {
              //console.log(nodeArray)
              for (let i = nodeArray.length - 1; i >= 0; i--) {
                let node = nodeArray[i];
                if (node.y === '') {
                  nodeArray.splice(i, 1);
                } else {
                  if (node.children) {
                    removeEmptyNodes(node.children);
                  }
                }
              }
            }
            removeEmptyNodes(rootColNodes);
          }

          // Sort column nodes if sorting is selected
          if (!groupByRatios && sortDir !== 'none') {
            let totalValues = ratioValues[ratioValues.length - 1];
            rootColNodes.sort((a, b) => {
              let d = totalValues[a.colIndex] - totalValues[b.colIndex];
              return sortDir === 'desc' ? d : -d;
            })
          }

          ratioDatas.push({
            ratio: ratio,
            rootColNodes: rootColNodes,
          });
        }
      }

      let rootNodes = [];
      if (groupByRatios || ratiosAsSeries) {
        let settingsModel = this.$store.state.analysisSettingsModel;
        let ratiosModel = settingsModel.find(function(el) {
          return el.name === 'ratios';
        });
        for (let ratioIndex = 0; ratioIndex < ratioDatas.length; ratioIndex++) {
          let ratioData = ratioDatas[ratioIndex];
          let ratio = ratioData.ratio;
          let ratioLabel = this.getRatioLabel(ratiosModel, ratio);
          if (ratio.calc_vars) {
            if (useRatioVariableAsName) {
              ratioLabel = variablesNameMap[ratio.calc_vars].label;
            } else {
              ratioLabel += ' (' + variablesNameMap[ratio.calc_vars].label + ')';
            }
          }
          if (!hideEmptyValues || ratioData.rootColNodes.length > 0) {
            let ratioNode = {
              id: "ratio" + ratioIndex,
              name: ratioLabel,
              value: 100,
              children: ratioData.rootColNodes
            };
            rootNodes.push(ratioNode);
          }
        }
        // Sorting data makes only sense if only one node per ratio exists.
        if (sortDir !== 'none') {
          rootNodes.sort((a, b) => {
            let d = a.children[0].y - b.children[0].y;
            return sortDir === 'desc' ? d : -d;
          })
        }
      } else if (ratioDatas.length > 0) {
        rootNodes = ratioDatas[0].rootColNodes;
      }


      // Limit number of displayed groups if limit is set. //ToDo: Auch auf untere Ebenen anwenden?
      let maxValueCount = settingsChartOptions._other && settingsChartOptions._other.displayedDataLim;
      let isDataReversed = settingsChartOptions.xAxis && settingsChartOptions.xAxis.xReversed;
      if (maxValueCount != null ) {
        if (isDataReversed) {
          rootNodes.splice(0, (rootNodes.length - maxValueCount) - (showTotal ? 1 : 0));
        } else {
          rootNodes.splice(maxValueCount, (rootNodes.length - maxValueCount) - (showTotal ? 1 : 0));
        }
      }

      // Create drilldown series
      let drilldownSeries = [];
      let addDrilldown = (node, pathString) => {
        pathString = pathString ? pathString + ' » ' + node.name : node.name;
        let series = {
          id: node.id,
          name: pathString,
          data: [],
          events: {
            click: this.pointClick
          },
          tooltip: {
            headerFormat: '',
            pointFormat: '<span style="font-size: '+ tooltipHeaderFontSize + 'px">{point.label}</span><br/><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yRounded}</b><br>'
          }
        }
        drilldownSeries.push(series);
        for (let childNode of node.children) {
          let y = childNode.y;
          let baseValue = childNode.baseValue;
          if (y != null && (!hideDrilldownZeroValues || y !== 0)) {
            let dataItem = {
              id: childNode.id,
              name: childNode.name,
              y: y === '' ? null : y,
              yRounded: childNode.yRounded,
              label: childNode.name + (baseValue == null ? '' : ' (N=' + baseValue + ')')
            };
            if ((childNode.varLevel !== varCount - 1) && !noDrilldown) {
              dataItem.drilldown = childNode.id;
              addDrilldown(childNode, pathString);
            }
            series.data.push(dataItem);
          }
        }
        // Sort data if sorting is selected
        if (sortDir !== 'none') {
          series.data.sort((a, b) => {
            let d = a.y - b.y;
            return sortDir === 'desc' ? d : -d;
          })
        }
        // Limit number of displayed values if limit is set.
        if (maxValueCount != null ) {
          if (isDataReversed) {
            series.data.splice(0, series.data.length - maxValueCount);
          } else {
            series.data.splice(maxValueCount);
          }
        }
      }

      // Create series from data tree model.
      let series = [];
      // For ratiosAsSeries, seriesNameMap maps ids to names, otherwise maps names to names (which makes it a Set).
      let seriesNameMap = new Map();
      // Collect all possible names from child nodes of root col nodes
      for (let rootNode of rootNodes) {
        if (ratiosAsSeries) {
          seriesNameMap.set(rootNode.id, rootNode.name);
        } else {
          rootNode.children.forEach(childNode => {
            seriesNameMap.set(childNode.name, childNode.name);
          });
        }
      }
      // Create series from collected names.
      for (let seriesId of seriesNameMap.keys()) {
        let seriesEntry = {
          id: seriesId,
          name: seriesNameMap.get(seriesId),
          point: {
            events: {
              click: this.pointClick
            }
          },
          data: [],
          tooltip: {
            headerFormat: '',
            pointFormat: '<span style="font-size: ' + tooltipHeaderFontSize + 'px">{point.label}</span><br/><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.yRounded}</b><br>'
          },
        };
        series.push(seriesEntry);
      }

      //console.log(JSON.stringify(rootNodes, null, 4))
      // Add root nodes to series. Note that child nodes are becoming the series, which then again contain the root nodes.
      // This may be quite confusing and is the reason why the conversion between tabulation data and the highcharts is a bit difficult to understand.
      for (let rootNodeIndex = 0; rootNodeIndex < rootNodes.length; rootNodeIndex++) {
        let rootNode = rootNodes[rootNodeIndex];
        if (ratiosAsSeries) {
          let seriesEntry = series[rootNodeIndex];
          // Root nodes are ratios.
          let childNodes = rootNode.children;
          for (let childNode of childNodes) {
            let rowIndex = resultRows.length - 1;
            let id = childNode.id + 'r' + rowIndex;
            let baseValue = childNode.baseValue;
            let y = childNode.y;
            let dataItem = {
              id: id + '_',
              name: childNode.name,
              row: 0,
              col: childNode.colIndex,
              y: y === '' ? null : y,
              yRounded: childNode.yRounded,
              label: rootNode.name + (baseValue == null ? '' : ' (N=' + baseValue + ')')
            };

            /*
            // Drilldown, not working
            if ((varCount > 2 || groupByRatios) && !noDrilldown) {
              for (let grandChildNode of childNode.children) {
                dataItem.drilldown = grandChildNode.id;
                addDrilldown(grandChildNode, childNode.name);
              }
            }
            */

            seriesEntry.data.push(dataItem);
          }
        } else {
          for (let seriesEntry of series) {
            let childNode = rootNode.children.find(e => seriesEntry.name === e.name);
            if (childNode == null) {
              // Complete missing data with empty data points. This will work around problems with order of root column nodes displayed in the chart.
              // ToDo: Deactivated, because too many values will be created.
              seriesEntry.data.push({
                y: null,
                visible: false,
                name: rootNode.name
              });
            } else {
              // If no row index is provided, we are still in columns. Use grand total row (last row) in this case.
              let rowIndex = childNode.rowIndex == null ? resultRows.length - 1 : childNode.rowIndex;
              let y = childNode.y;
              let id = rootNode.id + 'r' + rowIndex;
              let baseValue = rootNode.baseValue;
              let dataItem = {
                id: id + '_',
                name: rootNode.name,
                row: rowIndex,
                col: rootNode.colIndex,
                y: y === '' ? null : y,
                yRounded: childNode.yRounded,
                label: rootNode.name + (baseValue == null ? '' : ' (N=' + baseValue + ')')
              };
              if (hideZeroValues) {
                let isZero = y === "" || y === 0;
                if (isZero) {
                  delete dataItem.y;
                }
              }
              if ((varCount > 2 || groupByRatios) && !noDrilldown) {
                dataItem.drilldown = childNode.id;
                addDrilldown(childNode, rootNode.name);
              }
              seriesEntry.data.push(dataItem);
            }
          }
        }
      }



      for (let i = 0; i < settingsChartOptions.chart_series.length; i++) {
        if (i < series.length) {
          let seriesOptions = settingsChartOptions.chart_series[i];
          if (seriesOptions.type !== 'inherit' && seriesOptions.type !== null) {
            series[i].type = seriesOptions.type;
          }
          series[i].color = seriesOptions.color;
        }
      }


      // Only one ratio, we can use it's series and drilldown series directly
      //let series = ratioDatas[0].series;
      //let drilldownSeries = ratioDatas[0].drilldownSeries;

      // Set series and drilldown series to options
      chartOptions.series = series;
      if (drilldownSeries && drilldownSeries.length > 0) {
        if (chartOptions.drilldown == null) {
          chartOptions.drilldown = {};
        }
        chartOptions.drilldown.series = drilldownSeries;
      }

      if (varCount <= 2 && !groupByRatios) {
        // Create and set categories
        let categories = [];
        for (let rootNode of rootNodes) {
          categories.push(rootNode.name);
        }
        if (chartOptions.xAxis) {
          chartOptions.xAxis.categories = categories;
        }
      }


      // Negate categories
      let negativeCategories = settingsChartOptions._other && settingsChartOptions._other.negativeCategories;
      if (typeof negativeCategories === 'string') {
        let m = negativeCategories.match(/(?:([1-8]+).*?)/g);
        if (m != null) {
          for (let i = 0; i < m.length; i++) {
            let series = chartOptions.series[parseInt(m[i]) - 1];
            let data = series && series.data;
            if (data != null) {
              for (let dataEntry of data) {
                let y = parseFloat(dataEntry.y);
                if (!isNaN(y)) {
                  dataEntry.y = -y;
                }
                let yRounded = parseFloat(dataEntry.yRounded);
                if (!isNaN(yRounded)) {
                  dataEntry.yRounded = yRounded;
                }
              }
            }
          }
        }
      }

      let auxJSON = settingsChartOptions._other && settingsChartOptions._other.auxJSON;
      try {
        if (auxJSON != null && auxJSON !== '') {
          let data = JSON.parse(auxJSON);
          this.deepMergeOptions(chartOptions, data);
        }
      } catch (error) {
        console.error(error.stack, '\nJSON: "' + auxJSON + '"');
      }

      // Uncomment for debugging
      //console.log(JSON.stringify(chartOptions, null, 4))

      return chartOptions;
    },
    // Process string placeholders
    processPlaceholders(options) {
      let keys = Object.keys(options);
      keys.forEach(key => {
        let value = options[key];
        if (typeof value === 'string') {
          let m = value.match(/\{(.*?)\}/g);
          if (m != null) {
            for (let placeholder of m) {
              let placeholderType = placeholder.substring(1, placeholder.indexOf(':'));
              let placeholderVar = placeholder.substring(placeholder.indexOf(':') + 1, placeholder.length - 1);
              if (placeholderType === 'filter') {
                let filterValues = this.$store.state.filterValues;
                let placeholderFilter = filterValues[placeholderVar];
                let placeholderValue = placeholderFilter == null ? '' : placeholderFilter.toString();
                value = value.replace(placeholder, placeholderValue);
                options[key] = value;
              }
            }
          }
        } else if (typeof value === 'object' && value !== null) {
          this.processPlaceholders(value);
        }
      })
    },
    // Utility function to deep merge objects into another
    deepMergeOptions(target, ...sources) {
      if (!sources.length) return target;
      const source = sources.shift();

      let isObject = function(item) {
        return item && typeof item === 'object' && !Array.isArray(item);
      }

      if (isObject(target) && isObject(source)) {
        for (const key in source) {
          if (isObject(source[key])) {
            if (!target[key]) Object.assign(target, { [key]: {} });
            this.deepMergeOptions(target[key], source[key]);
          } else if (source[key] != null && source[key] !== '') {
            Object.assign(target, { [key]: source[key] });
          }
        }
      }
      return this.deepMergeOptions(target, ...sources);
    },
    getRatioLabel(ratiosModel, ratio) {
      let ratioLabel = '';
      if (ratiosModel != null && ratio != null) {
        let ratioName = ratio.name;
        let ratioModel = ratiosModel.items.find(el => el.name === ratioName);
        if (ratioModel != null) {
          let locale = getLocale();
          ratioLabel = (ratioModel.label_int && ratioModel.label_int[locale]) || ratioModel.label;
        } else {
          // fallback, should not happen
          ratioLabel = ratioName;
        }
      }
      return ratioLabel;
    }

  }
}
</script>


<style lang="scss" scoped>

#tq-data-chart {

  position: relative;
  height: 100%;
  width: 100%;
  display: flex;

  padding: 7px;
  overflow: auto;

  .chart-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .chart {
    height: 100%;
    width: 100%;
  }

  .resizable {
    margin: auto;
    position: relative;
    width: 90%;
    height: 70%;
    max-height: 99%; /* This prevents the parent from growing when resizing manually */
  }

  .resize-handle{
    width: 10px;
    height: 10px;
    border: 3px solid #aaa;
    position: absolute;
  }

  .resize-handle.top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize; /*resize cursor*/
  }

  .resize-handle.top-right {
    right: -5px;
    top: -5px;
    cursor: nesw-resize;
  }

  .resize-handle.bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
  }

  .resize-handle.bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
  }

  .container {
    position: absolute;
    background: red;
    overflow: auto;
  }

  :deep {
    .highcharts-container {
      width: 100% !important;
      height: 100% !important;
    }

    .highcharts-exporting-group {
      display: none !important;
    }
  }

}
</style>