<template>
<div xxx-v-if="ready" id="tq-results-table">
  <div v-if="$store.getters.cellFilter" class="text-subtitle-1" style="flex 0 0 0; display: flex; align-items: center; color: #dd2840; padding: 4px 16px; background: #f5f5f5; border-top: 1px solid #ddd;">
    <span style="margin: 0 5px 0 0;">{{ $t('texts.ADDITIONAL_FILTER___') }}</span>
    <span>
      <v-tooltip bottom open-delay="300" :offset-overflow="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="removeCellFilter"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('tooltips.RESET_FILTERS') }}</span>
      </v-tooltip>
    </span>
  </div>

  <div style="display: flex; height: 100%;" ref="columns">
    <div style="display: flex; flex-direction: column; flex: 3 0 0; overflow: auto; height: 100%;">
      
      <!-- AgGrid container -->
      <div 
        class="ag-theme-balham"
        style="flex: 1 0 0; border-top: 1px solid rgb(221, 221, 221);"
        ref="ag-grid"
      ></div>

    </div>
    <div class="result-detail" v-bind:class="{ 'result-detail-close': resultsDetailClose, 'result-detail-opened': resultsDetailOpened }" ref="columnDetail">
      <!--<div v-if="selectedTableDataRows.length > 0" class="drawer-handle-container">
        <div class="drawer-handle md-elevation-4">
          <div class="drawer-handle-inner"><md-icon>keyboard_arrow_right</md-icon></div>
          <md-tooltip>{{ true ? $t('tooltips.CLOSE_SIDEBAR') : $t('tooltips.OPEN_SIDEBAR')}}</md-tooltip>
        </div>
      </div>-->
      <div class="result-detail-handle" @mousedown="resizeResultDetail"></div>
      <results-detail class="results-detail" v-if="selectedTableDataRows.length > 0" ref="resultsDetail" v-bind:tableDataRow="selectedTableDataRows[0]" v-bind:onClose="resultDetailClose" :verbatim="verbatimVar" :language="lang" :isRezising="isResizeResultDetail" @update:language="lang=$event"/>
    </div>
  </div>

  <div class="dataset-button-wrapper">
    <v-tooltip bottom open-delay="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isCati"
          class="start-button"
          fab
          dark
          small
          color="primary"
          @click="startRow"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>
            mdi-play
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('tooltips.START') }}</span>
    </v-tooltip>

    <v-tooltip bottom open-delay="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="viewSettings.addDatasets"
          class="add-button"
          fab
          dark
          small
          color="primary"
          @click="insertRow"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('tooltips.ADD_DATASET') }}</span>
    </v-tooltip>
  </div>

  <v-dialog
    v-model="exportDialogVisible"
    width="500"
    persistent
  >
    <v-card v-if="exportSettings">
      <v-card-title class="text-h6 white--text primary">
        Export
      </v-card-title>

      <v-card-text style="padding-top: 20px;">
        <div class="text-subtitle-1">{{ $t('labels.filetype') }}</div>
        <div style="margin-left: 16px;">
          <v-radio-group v-model="exportSettings.selFileType">
            <v-radio
              key="xslx"
              label="Excel"
              value="xslx"
            ></v-radio>
            <v-radio
              key="csv"
              label="CSV"
              value="csv"
            ></v-radio>
            <v-radio
              v-if="exportSettings.pdf.available"
              key="pdf"
              label="PDF"
              value="pdf"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>

      <v-card-actions style="background-color: rgb(244, 244, 244); border-top: 1px solid rgb(233, 233, 233);">
        <v-spacer></v-spacer>
        <v-btn text @click="exportDialogVisible = false">{{ $t('actions.cancel') }}</v-btn>
        <v-btn color="primary" @click="exportDialogVisible = false; startExport();">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import api from '@/services/api'
import fileExport from '@/services/fileExport'
import { t, getLocale } from '@/i18n'
import _ from 'lodash'
import error from '@/services/error'
import gridRenderers from '@/services/aggrid/gridRenderers'
import urlService from '@/services/url'
import dataEntry from '@/services/dataEntry'
import agGridUtil from '@/services/aggrid/util'
import ViewModel from '@/models/view'
import results from '@/services/results'
import localStorageHelper from '@/services/localStorageHelper'
import { initPhoneCall } from '@/services/phoneCall'
import confirmDialog from '@/services/confirmDialog'

import { createGrid } from "ag-grid-community"

const DOWNLOAD_SIZE = 1000
const EXPORT_TIMEOUT_MS = 300

export default {
  label: 'ResultsTable',
  computed: {
    viewSettings() {
      return ViewModel.getSettings(this.$store.state.view)
    },
    showActionCell() {
      return this.viewSettings.deleteDatasets || this.viewSettings.duplicateDatasets
    },
    locale() {
      return getLocale()
    }
  },
  watch: {
    locale(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        this.init(true)
      }
    },
    // Update data if filter query change
    '$store.state.query': {
      async handler() {
        try {
          await this.init(true)
        } catch(e) {
          error.runtimeError(e)
        }
      },
      deep: true
    },
    '$store.state.analysisSettings': function(newSettings) {
      this.updateSettings(newSettings)
      this.$store.commit('setAnalysisSettingsDatasetsDirty', false)
    },
    '$store.state.cellFilter': function() {
      this.init(true)
    },
    enabled() {
      if (this.ready) {
        this.init(this.$store.state.analysisSettingsDatasetsDirty)
        this.$store.commit('setAnalysisSettingsTabulationDirty', false)
      } else {
        this.updateSettings(this.$store.state.analysisSettings)
      }
    }  
  },
  props: {
    enabled: Boolean
  },
  data: () => {
    return {
      agGrid: null,
      c: null,
      changed: true,
      clickTimeout: null,
      columnDefs: null,
      ds: 0, // fraction num
      exportDialogVisible: false,
      exportSettings: null,
      fields: [],
      inInit: false,
      isCati: false,
      isResizeResultDetail: false,
      lastDblClickTime: Date.now(),
      n: 0, // record num
      ready: false, // boolean when side is ready for rendering
      resultsDetailClose: true,
      resultsDetailOpened: false,
      rowData: null,
      rowIndex: null,
      selectedTableDataRows: [],
      deleteAction: false,
      duplicateAction: false,
      performedClicks: 0,
      refreshServerSide: false,
      sorters: [{
        colId: '_id',
        sort: 'asc'
      }],
      surveyCurrentPageTitle: null,
      updated: false,
      verbatimVar: undefined,
      variables: null,
      lang: null
    }
  },
  methods: {
    refresh() {
      if (this.enabled) {
        this.init(true)
      } else {
        this.changed = true
      }
    },
    updateSettings(settings) {
      if (!this.enabled) {
        return
      }
      // replace columns with agAgrid specific naming
      // 1. Replace fractions notation
      //    .fractions. => :
      // 2. Replace dot notation
      //    . => -

      this.visibleColIds = settings.datasets_columns.map(datasets_column => datasets_column.replace('.fractions.', ':').replace(/\./g, '---'))
      
      let verbatimVar = settings.level === 'verbatim' ? settings.verbatim_var : null

      if (this.verbatimVar !== verbatimVar || !this.ready) {
        this.init(true)
      } else {
        this.updateVisibleColumns()
      }
    },
    async init(changed) {
      if (!this.$store.state.analysisSettings) {
        console.error(new Error())
        return
      }

      try {
        let analysisSettings = this.$store.state.analysisSettings
        let verbatimVar = analysisSettings.level === 'verbatim' ? analysisSettings.verbatim_var : null
        let verbatimVarChanged = verbatimVar !== this.verbatimVar
        
        this.verbatimVar = verbatimVar
        this.changed = changed || this.changed
        this.isCati = !!_.get(this.$store.state.view, 'settings.catiParams.cati')

        if (this.inInit || !this.enabled || !this.changed) {
          return
        }
      
        this.inInit = true

        this.changed = false

        this.gridDataSource = {
          rowCount: null, // behave as infinite scroll
          getRows: async params => {
            let request = params.request
            let sortModel = request.sortModel
            let startRow = request.startRow
            let endRow = request.endRow
            let success = params.success
            let isFirstRows = startRow === 0

            this.sortModel = sortModel

            if (isFirstRows) {
              //this.$store.dispatch('setLoading', true)

              // deselect if load row data for the first time (e.g. sorting, change filter, ...)
              this.agGrid.deselectAll()
              this.selectedTableDataRows = []
            } else {
              this.$store.dispatch('freezeLoading')
            }
          
            let { tableData, lastPos } = await results.getRowsBatch(sortModel, startRow, endRow, null, false, this.verbatimVar, this.fields)

            if (isFirstRows) {
              //this.$store.dispatch('setLoading', false)
            } else {
              this.$store.dispatch('unfreezeLoading')
            }

            success({
              rowData: tableData,
              rowCount: lastPos
            })

            if (isFirstRows) {
              this.autosizeAllColumns()
            }
          }
        }
        //this.ready = false
        let store = this.$store

        // TODO: move to store
        store.commit('setCachedResultObjBySortIndexKey', {})

        await store.dispatch('getSourceViewSortedResultsMeta', {
          verbatimVar: this.verbatimVar
        })

        //this.variables = (await api.call('getVariables')).data
        this.variables = this.$store.state.view.variables

        this.createFields(verbatimVarChanged)

        this.createAgGrid()

        // ***** Twilio init ***********************
        // The values for 'settings.catiParams' ca be stored on '<project>.views.<view document>' or in 'source.source.viewVals'. The values from view have priority.
        let catiEnabled = true

        store.dispatch('setPhoneCall', null) 

        if (catiEnabled === true && !store.state.view) {
          catiEnabled = false
          //console.log('no view')
        }

        if (catiEnabled === true && store.state.phoneCall != null) {
          catiEnabled = false
          //console.log('phonecall not null')
        }

        if (catiEnabled === true && !store.state.view.settings) {
          catiEnabled = false
          //console.log('no settings')
        }

        if (catiEnabled === true && !store.state.view.settings.catiParams) {
          catiEnabled = false
          //console.log('no catiParams')
        }

        if (catiEnabled === true && !store.state.view.settings.catiParams.cati) {
          catiEnabled = false
          //console.log('no cati')
        }

        if (catiEnabled === true && store.state.view.settings.catiParams.cati !== true) {
          catiEnabled = false
          //console.log('cati = ' + cati) 
        }

        if (catiEnabled === true && !store.state.view.settings.catiParams.phone_account) {
          catiEnabled = false
          //console.log('no phone_account')
        }

        if (catiEnabled === true) {
          let fromNumber = store.state.view.settings.catiParams.phone_account.number
              if ( fromNumber === null || !(typeof fromNumber === 'string' || fromNumber instanceof String)) {
                throw 'store.state.view.catiParams.phone_account.number is wrong! \n store.state.source.name = ' + store.state.source.name
              }        

              let phoneCallLocal = await initPhoneCall()
              phoneCallLocal.fromNumber  = fromNumber
              store.dispatch('setPhoneCall', phoneCallLocal)        
        }
        this.ready = true
      } catch(e) {
        error.runtimeError(e)
      }

      // init export settings

      let exportSettings = JSON.parse(JSON.stringify(this.$store.state.view.export || {}))
      exportSettings.selFileType = 'xslx'
      exportSettings.pdf = exportSettings.pdf || {}
      this.exportSettings = exportSettings
      this.inInit = false
    },
    async deleteRow(params) {
      let doDelete = await confirmDialog.open(
        this.$t('actions.confirm'),
        null,
        {
          agreeText: this.$t('actions.delete')
        }
      )

      if (!doDelete) {
        return
      }

      let store = this.$store

      store.commit('setLastMetaCall', null)
      store.commit('setCachedResultObjBySortIndexKey', {})

      await store.dispatch('deleteSourceViewResult', {
        id: params.data._id
      })
      
      await store.dispatch('getSourceViewSortedResultsMeta', {
        verbatimVar: this.verbatimVar
      })

      // TODO: #676 - revert this to refreshServerSide() and remove bruteRefresh
      // when AgGrid is updated to AgGrid >= 28.x.x
      // Use this function for AgGrid < 28.x.x
      //this.bruteRefreshData()

      this.agGrid.refreshServerSide({ purge: true })
    },
    async refreshDatasets() {
      let store = this.$store

      store.commit('setLastMetaCall', null)
      store.commit('setCachedResultObjBySortIndexKey', {})
      store.commit('setResultMap', {})

      await store.dispatch('getSourceViewSortedResultsMeta', {
        verbatimVar: this.verbatimVar,
        noCache: true
      })

      // TODO: #676 - revert this to refreshServerSide() and remove bruteRefresh
      // when AgGrid is updated to AgGrid >= 28.x.x
      // Use this function for AgGrid < 28.x.x
      //this.bruteRefreshData()

      // Use this code for AgGrid >= 28.x.x
      this.agGrid.refreshServerSide({ purge: true })
    },
    async duplicateRow(params) {
      let rowData = this.$store.state.resultMap[params.data._id]

      await this.insertData(_.omit(rowData, ['_id', '_description']))
    },
    exportData() {
      // called from parent component
      this.exportDialogVisible = true
    },
    removeCellFilter() {
      this.$store.dispatch('setCellFilter', null)
    },
    async startExport() {
      switch (this.exportSettings.selFileType) {
        case 'xslx':
          await this.exportAsExcel()
          break
        case 'csv':
          await this.exportAsCsv()
          break
        case 'pdf':
          await this.exportAsPdf()
          break
      }

      let logData = {
        fileType: this.exportSettings.selFileType
      }

      api.call('pushLog', { type: 'export', data: logData })
    },
    createColumnDefs(options) {
      options = Object.assign({
        onlyVisible: false
      }, options || {})

      // ag-gid columns
      let columnDefs = [
        {
          headerName: "Num",
          field: '__num',
          pinned: 'left',
          lockPosition: true,
          lockVisible: true,
          lockPinned: true,
          hide: false,
          sortable: false,
          resizable: true,
          suppressMovable: true,
          suppressHeaderMenuButton: true,
          cellClass: 'index-cell',
          width: 80
        }
      ]

      if (this.showActionCell) {
        let actionButtons = []

        if (this.viewSettings.deleteDatasets) {
          actionButtons.push('<i class="material-icons" data-action="delete">delete</i>')
        }

        if (false && this.viewSettings.duplicateDatasets) {
          actionButtons.push('<i class="material-icons" data-action="duplicate">content_copy</i>')
        }

        columnDefs.push({
          headerName: '',
          field: '__action',
          editable: false,
          pinned: 'right',
          lockPosition: true,
          lockVisible: true,
          lockPinned: true,
          hide: false,
          sortable: false,
          resizable: false,
          suppressMovable: true,
          suppressHeaderMenuButton: true,
          width: 50,
          cellRenderer: function(params) {
            return actionButtons.join('')
          }
        })
      }

      let skipIndexNum = columnDefs.length
      let hiddenIndex = this.visibleColIds.length - (skipIndexNum - 1)
      let unknownVisibleColIds = []

      let visibleColIds = this.visibleColIds.filter(visibleColId => {
        if (this.fields.find(field => field.key === visibleColId)) {
          return true
        } else {
          unknownVisibleColIds.push(visibleColId)

          return false
        }
      })

      if (unknownVisibleColIds.length > 0) {
        error.runtimeError(null, {
          silent: true,
          clientDebugData: {
            reason: `unknown column(s): ${unknownVisibleColIds.join(',')}`,
            fields: this.fields,
            visibleColIds: this.visibleColIds
          }
        })

        this.visibleColIds = visibleColIds

        this.updateDatasetColumns(this.visibleColIds)
      }
      
      this.fields.forEach(field => {
        let index = this.visibleColIds.indexOf(field.key)
        let found = index >= 0

        if (!found && options.onlyVisible) {
          return
        }

        let insertIndex = found ? index : hiddenIndex++

        let columnDef = {
          headerName: field.label,
          field: field.key,
          hide: index === -1,
          sortable: true,
          resizable: true
        }

        if (field.type === 'link') {
          columnDef.cellRenderer = gridRenderers.LinkCellRenderer
        } else if (field.type === 'text') {
          columnDef.cellRenderer = gridRenderers.TextCellRenderer
        } else if (field.type === 'verbatim_n') {
          columnDef.cellRenderer = gridRenderers.VerbatimCellRenderer
        } else if (field.type === 'surveyjs-creator') {
          columnDef.cellRenderer = gridRenderers.JsonCellRenderer
        }

        columnDefs[insertIndex + skipIndexNum] = columnDef
      })

      if (columnDefs.includes(undefined)) {
        let diffColumnDefAndFields = _.difference(columnDefs.map(x =>x && x.field), this.fields.map(x => x && x.key))
        let diffVisibleColIdsAndFields = _.difference(this.visibleColIds, this.fields.map(x => x && x.key))
        let diffVisibleColIdsAndColumnDefs = _.difference(this.visibleColIds, columnDefs.map(x =>x && x.field))

        console.log('------')
        console.log(this.visibleColIds)
        console.log(_.difference(this.fields.map(x => x && x.key), this.visibleColIds))
        console.log(_.difference(this.visibleColIds, this.fields.map(x => x && x.key)))

        error.runtimeError(null, {
          silent: true,
          message: `diffVisibleColIdsAndFields: ${diffVisibleColIdsAndFields.join(',')}`,
          clientDebugData: {
            reason: `invalid columnDefs array: ${diffVisibleColIdsAndFields.join(',')}`,
            fields: this.fields,
            visibleColIds: this.visibleColIds,
            columnDefs: columnDefs,
            onlyVisible: options.onlyVisible,
            diffColumnDefAndFields: diffColumnDefAndFields,
            diffVisibleColIdsAndFields: diffVisibleColIdsAndFields,
            diffVisibleColIdsAndColumnDefs: diffVisibleColIdsAndColumnDefs
          }
        })

        columnDefs = columnDefs.filter(columnDef => columnDef !== undefined)
      }

      return columnDefs
    },
    createExportColumnDefs() {
      let columnDefs = this.createColumnDefs({
        onlyVisible: true
      })

      if (this.verbatimVar && !columnDefs.find(columnDef => {
        return columnDef.field == this.verbatimVar + ':verbatim'
      })) {
        // add verbatim to export if not already existing
        columnDefs.push({
          headerName: 'Verbatim',
          field: 'verbatim',
          cellRenderer: gridRenderers.VerbatimCellRenderer
        })
      }

      return columnDefs
    },
    /*
    * This function is needed because AgGrid has no build in functionality to prevent
    * a click when a double clicked is trigered
    */
    async debounceCellClick(delay) {
      return new Promise(resolve => {
        if ((Date.now() - this.lastDblClickTime) < 1000) {
          this.performedClicks = 0
          return
        }

        this.performedClicks++

        this.clickTimeout = window.setTimeout(() => {
          if (this.performedClicks === 1) {
            this.performedClicks = 0
            resolve()
          } else {
            this.performedClicks = 0
          }
        }, delay)

        if (this.performedClicks > 1) {
          window.clearTimeout(this.clickTimeout)
        }
      })
    },
    getDataInterface(type) {
      switch (type) {
        case 'cati':
          return {
            next: async () => {
              let returnVal = (await api.call('getSourceViewCatiResult', {
                sourceName: this.$store.state.source.name,
                viewName: this.$store.state.view.name,
                data: {
                  filter: this.$store.state.query
                },
                params: {
                  timeoutKey: localStorageHelper.getTimeoutKey()
                }
              })).data

              if (returnVal) {
                return {
                  data: returnVal.data,
                  hasNext: returnVal.hasNext
                }
              } else {
                return null
              }
            },

            setData: async obj => {
              await this.updateData(obj)
            }
          }
        case 'update':
          return {
            next: async () => {
              ++this.rowIndex
              let nextRow = this.agGrid.getDisplayedRowAtIndex(this.rowIndex)
              let id = nextRow && nextRow.data && nextRow.data._id
              let rowData = id && this.$store.state.resultMap[id]

              if (rowData) {
                return {
                  data: rowData,
                  hasNext: (this.rowIndex + 1) < this.$store.state.sortedResultMeta.ds
                }
              } else {
                return null
              }
            },

            setData: async obj => {
              await this.updateData(obj)
            }
          }
        case 'insert':
          return {
            next: null,

            setData: async obj => {
              await this.insertData(obj)
            }
          }
      }
    },
    getDownloadSize(format) {
      let exportSettings = this.exportSettings

      if (exportSettings && exportSettings[format] && exportSettings[format].max_datasets !== undefined) {
        return parseInt(exportSettings[format].max_datasets)
      }

      return DOWNLOAD_SIZE
    },
    async exportAsCsv() {
      let { tableData } = await results.getRowsBatch(this.sortModel, 0, this.getDownloadSize('csv'), EXPORT_TIMEOUT_MS, true, this.verbatimVar, this.fields)
      fileExport.exportCSV(this.createExportColumnDefs(), tableData, this.lang)
    },
    async exportAsExcel() {
      let { tableData } = await results.getRowsBatch(this.sortModel, 0, this.getDownloadSize('excel'), EXPORT_TIMEOUT_MS, true, this.verbatimVar, this.fields)
      
      fileExport.exportExcel(this.createExportColumnDefs(), tableData, this.lang)
    },
    async exportAsPdf() {
      let { tableData } = await results.getRowsBatch(this.sortModel, 0, this.getDownloadSize('pdf'), EXPORT_TIMEOUT_MS, true, this.verbatimVar, this.fields)
      
      fileExport.exportPDF(this.createExportColumnDefs(), tableData, this.exportSettings, this.lang)
    },
    getSelectedDatasets() {
      return this.selectedTableDataRows.map(row => row.__result)
    },
    updateVisibleColumns() {
      let visibleColIds = this.visibleColIds
      let columns = this.agGrid.getColumns()

      let changableVisibleColIds = []
      let changableHiddenColIds = []

      columns.forEach(column => {
        let colId = column.colId

        if (!['__num', '__action'].includes(colId)) {
          if (visibleColIds.indexOf(colId) >= 0) {
            if (!column.visible) {
              changableVisibleColIds.push(colId)
            }
          } else {
            if (column.visible) {
              changableHiddenColIds.push(colId)  
            }
          }
        }
      })

      if (changableVisibleColIds.length > 0) {
        this.agGrid.setColumnsVisible(changableVisibleColIds, true)
      }

      if (changableHiddenColIds.length > 0) {
        this.agGrid.setColumnsVisible(changableHiddenColIds, false)
      }

      // update column order
      for (let i = 0; i < visibleColIds.length; ++i) {
        let visibleColId = visibleColIds[i]

        let columnIndex = this.agGrid.getAllDisplayedColumns().findIndex(column => column.colId === visibleColId)

        if (columnIndex !== i + 1) {
          this.agGrid.moveColumn(visibleColId, i + 1)
        }
      }

      /*
      * START fix AgGrid vue issues
      *
      * columnDefs needs to be in sync with the updates which are done above over the columnApi
      */
      columns = this.agGrid.getAllGridColumns()

      let updatedColumnDefs = []
      for (let i = 0; i < columns.length; ++i) {
        let column = columns[i]
        let columnDef = this.columnDefs.find(columnDef => columnDef.field === column.colId)

        if (!columnDef) {
          // Issue #699 add more debugging info
          error.throw(null, {
            clientDebugData: {
              reason: 'columnDef not found in columnDefs',
              colId: column.colId,
              columnColIds: columns.map(column => column.colId),
              columnDefFields: this.columnDefs.map(columnDef => columnDef.field)
            }
          })
        }

        updatedColumnDefs.push(columnDef)
      }

      this.columnDefs.splice(0, this.columnDefs.length, ...updatedColumnDefs)

      this.columnDefs.forEach(columnDef => {
        columnDef.hide = !['__num', '__action'].includes(columnDef.field) && visibleColIds.indexOf(columnDef.field) === -1
      })
      /*
      * END fix AgGrid vue issues
      */
    },
    resultDetailClose() {
      this.resultsDetailOpened = false
      this.resultsDetailClose = true
      
      window.setTimeout(() => {
        this.agGrid.deselectAll()
      }, 300)
    },
    moveSelection(num) {
      let selectedNodes = this.agGrid.getSelectedNodes()

      if (selectedNodes && selectedNodes.length === 1) {
        let selectedNode = selectedNodes[0]

        let rowIndex = selectedNode.rowIndex + num

        if (rowIndex >= this.$store.state.sortedResultMeta.ds) {
          return null
        }

        this.agGrid.ensureIndexVisible(rowIndex)
        
        this.agGrid.forEachNode(node => {
          if (node.rowIndex === rowIndex) {              
            node.setSelected(true)
            selectedNode.setSelected(false)
          }
        })
      }
    },
    keyup(event) {
      // up and down navigation between selected rows
      // with arrow keys
      if ((this.selectedTableDataRows.length > 0) && (event.key === "ArrowUp" || event.key === "ArrowDown")) {
        this.moveSelection(event.key === "ArrowUp" ? -1 : 1)
      }
    },
    onSelectionChanged() {
      let delay = this.resultsDetailOpened ? 0 : 500

      window.setTimeout(() => {
        let singleCaseViews = this.$store.state.view.singleCaseViews
        let selectedRows = this.agGrid.getSelectedRows()

        this.selectedTableDataRows = selectedRows || []

        // only if singleCaseViews is not empty open box
        if (singleCaseViews && singleCaseViews.length > 0) {
          let open = selectedRows != null && selectedRows.length === 1;
          this.resultsDetailClose = !open;
          window.setTimeout(() => {
            this.resultsDetailOpened = open
          }, 300)
        }
      }, delay)
    },
    onDisplayedColumnsChanged(params) {
      let analysisSettings = this.$store.state.analysisSettings
      let analysisSettingsColumns = analysisSettings.datasets_columns
      let newAnalysisSettingsColumns = []
      let displayedColumns = this.agGrid.getAllDisplayedColumns()
      
      let equal = analysisSettingsColumns.length === displayedColumns.length - 1

      displayedColumns.forEach(displayedColumn => {
        let colId = displayedColumn.colId
        if (['__num', '__action'].includes(colId)) {
          return
        }
        let newAnalysisSettingsColumn = colId.replace(/---/g, '.').replace(':', '.fractions.')
        newAnalysisSettingsColumns.push(newAnalysisSettingsColumn)
      })

      for (let i = 0; equal && i < analysisSettingsColumns.length; ++i) {
        equal = analysisSettingsColumns[i] !== newAnalysisSettingsColumns[i]
      }

      if (!equal) {
        this.updateDatasetColumns(newAnalysisSettingsColumns)
      }
    },
    onSortChanged() {
      let analysisSettings = this.$store.state.analysisSettings

      var colState = this.agGrid.getColumnState()
      var sortModel = colState
        .filter(sortModel => sortModel.sort != null)
        .map(sortModel => _.pick(sortModel, ['colId', 'sort', 'sortIndex']))
      
      analysisSettings.datasets_sort_model = sortModel

      urlService.updateAnalysisUrl('datasets', true)
    },
    async onCellClicked(params) {
      await this.debounceCellClick(400)

      if (
        params.column.colId === '__action' &&
        params.event.target.dataset.action
      ) {
        let action = params.event.target.dataset.action

        if (action === 'delete') {
          await this.deleteRow(params)
        }

        if (action === 'duplicate') {
          await this.duplicateRow(params)
        }
      }
    },
    async closeUpdateDialog() {
      if (this.refreshServerSide) {
        this.$store.commit('setCachedResultObjBySortIndexKey', {})

        this.agGrid.refreshServerSide({ purge: true })

        this.refreshServerSide = false
      }
    },
    async openUpdateDialog(rowIndex, id, verbatimNum) {
      const store = this.$store

      this.rowIndex = rowIndex
      this.lastDblClickTime = Date.now()

      if (this.viewSettings.editable) {
        this.resultDetailClose()
        
        let rowData = store.state.resultMap[id]

        let { dataChanged } = await dataEntry.open({
          dataInterface: this.getDataInterface(this.isCati ? 'cati' : 'update'),
          isCati: this.isCati,
          rowData: rowData,
          showPhoneCallDialer: true,
          saveAndNextButton: true,
          hasNext: (rowIndex + 1) < store.state.sortedResultMeta.ds,
          surveyObj: store.state.view.survey,
          view: store.state.view
        })

        if (dataChanged) {
          await this.refreshDatasets()
        }
      }
    },
    async onRowDoubleClicked(params) {
      // for verbatim rows the id is seperated with <id>:<rowNum>
      let verbatimSplit = params.data._id.split(':')

      let id = verbatimSplit[0]
      let verbatimNum = verbatimSplit[1]

      await this.openUpdateDialog(params.rowIndex, id, verbatimNum)
    },
    createFields(verbatimVarChanged) {
      // create expanded fields with view variables and verbatim variables 
      let expandedFields = [{
        isVerbatim: false,
        label: 'ID',
        name: '_id',
        type: 'number',
        key: '_id'
      }]

      this.$store.state.sortedResultMeta.fields.forEach((field, i) => {
        if (field.type === 'verbatim_n' || field.type === 'verbatim_n_html') {
          field.codingvars.forEach(codingvar => {
            // prefix key for all verbatim variables with verabtim name and ':'
            let key = (field.name + ':' + codingvar.name).replace(/\./g, '---')

            expandedFields.push(Object.assign({}, codingvar, {
              isVerbatim: true,
              key: key
            }))
          })
        } else {
          // need to replace field name '.' chars with '---' because AgGrid uses the '.' seperator to access nested objects
          let key = field.name.replace(/\./g, '---')

          expandedFields.push(Object.assign({}, field, {
            isVerbatim: false,
            key: key
          }))
        }
      })

      this.fields = expandedFields

      /*if (!this.columnDefs || verbatimVarChanged) {
        // Only set this.columnDefs if needed
        // there is a problem with AgGrid when overwriting with the same columnDefs values
        // equaly named colIds will be underscored so e.g. beanstandeungen:manngelart are changed to beanstandeungen:manngelart_1
        
        // TODO: change of verbatim is not tested
        this.columnDefs = this.createColumnDefs()
      }*/

      this.columnDefs = this.createColumnDefs()
    },
    createAgGrid() {
      this.destroyAgGrid()

      const agGridElem = this.$refs['ag-grid']

      const gridOptions = {
        cacheOverflowSize: 2,
        //class: "ag-theme-balham",
        components: {
          vueMaterialDatePicker: agGridUtil.getVueMaterialDatePicker()
        },
        maxConcurrentDatasourceRequests: 2,
        multiSortKey: "ctrl",
        rowModelType: "serverSide",
        rowSelection: "multiple",
        suppressCellFocus: true,
        suppressColumnVirtualisation: true,
        suppressContextMenu: true,
        columnDefs: this.columnDefs,
        onCellClicked: this.onCellClicked,
        onDisplayedColumnsChanged: this.onDisplayedColumnsChanged,
        onGridReady: this.onGridReady,
        onSelectionChanged: this.onSelectionChanged,
        onSortChanged: this.onSortChanged,
        onRowDoubleClicked: this.onRowDoubleClicked
      }
      
      this.agGrid = createGrid(agGridElem, gridOptions)
      
      // for backward compatibility also check for results_sort_model
      let datasetSortModel = this.$store.state.analysisSettings.results_sort_model || this.$store.state.analysisSettings.datasets_sort_model

      if (datasetSortModel) {
        // allow dot syntax ("." and '.fractions.')
        datasetSortModel = datasetSortModel.map(datasetSortModelField => { 
          return { 
            colId: datasetSortModelField.colId.replace('.fractions.', ':').replace(/\./g, '---'),
            sort: datasetSortModelField.sort,
            sortIndex: datasetSortModelField.sortIndex
          }
        })

        this.agGrid.applyColumnState({
          state: datasetSortModel,
          defaultState: { sort: null }
        })
      }

      this.agGrid.setGridOption("serverSideDatasource", this.gridDataSource)
    },
    destroyAgGrid() {
      if (this.agGrid) {
        this.agGrid.destroy()
        this.agGrid = null
      }
    },
    autosizeAllColumns() {
      let allColumnIds = this.agGrid.getColumns().map(column => column.colId)
      this.agGrid.autoSizeColumns(allColumnIds)
    },
    resizeResultDetail(event) {
      let startWidth = this.$refs.columnDetail.offsetWidth
      let minWith = 300
      let maxWidth = this.$refs.columns.offsetWidth - 50
      let startX = event.x

      this.isResizeResultDetail = true

      document.querySelector("html").style.userSelect = 'none'

      let onMouseMove = event => {
        let newWidth = startWidth + (startX - event.x)

        newWidth = newWidth < minWith ? minWith : newWidth > maxWidth ? maxWidth : newWidth
        this.$refs.columnDetail.style.flex = '0 0 ' + newWidth + 'px'
      }

      let onMouseUp = () => {
        window.removeEventListener('mousemove', onMouseMove)
        window.removeEventListener('mouseup', onMouseUp)
        document.querySelector("html").style.removeProperty("user-select")

        this.isResizeResultDetail = false
      }

      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
    },

    updateDatasetColumns(analysisSettingsColumns) {
      let analysisSettings = this.$store.state.analysisSettings

      analysisSettings.datasets_columns = analysisSettingsColumns
        
      this.$store.dispatch('setAnalysisSettings', analysisSettings)

      urlService.updateAnalysisUrl('datasets', true)
    },

    // TODO: #676 - remove this function when AgGrid is updated to AgGrid >= 28.x.x
    /*async bruteRefreshData() {
      this.ready = false
      await this.$nextTick()
      this.ready = true
      await this.$nextTick()
    },*/

    async insertData(obj) {
      if (!obj) {
        return
      }

      let { data } = obj

      let store = this.$store

      //store.commit('setLastMetaCall', null)
      //store.commit('setCachedResultObjBySortIndexKey', {})

      /*let newData = {}
      for (let key in data) {
        let val = data[key]
        key = key.replace(/---/g, '.')
        newData[key] = val
      }*/
    
      store.dispatch('setLoading', true)

      await api.call('insertSourceViewResult', {
        data: {
          data: data
        }
      })

      store.dispatch('setLoading', false)

      /*await store.dispatch('getSourceViewSortedResultsMeta', {
        verbatimVar: this.verbatimVar
      })

      // TODO: #676 - revert this to refreshServerSide() and remove bruteRefresh
      // when AgGrid is updated to AgGrid >= 28.x.x
      // Use this function for AgGrid < 28.x.x
      this.bruteRefreshData()

      // Use this code for AgGrid >= 28.x.x
      this.gridApi.refreshServerSide({ purge: true })*/

      this.$emit('on-change')
    },
    async updateData(obj) {
      if (!obj) {
        return
      }

      let { data, rowData, completeSurvey, dialogType } = obj

      let store = this.$store
      let id = rowData._id
      let version = rowData && rowData._description && rowData._description.version 

      let updateReturnObj = (await api.call('updateSourceViewResult', {
        id: id,
        data: {
          data: data,
          completeSurvey: !!completeSurvey,
          dialogType: dialogType
        },
        params: {
          version: version,
          timeoutKey: localStorageHelper.getTimeoutKey()
        }
      })).data

      // update row data version
      _.set(rowData, '_description.version', updateReturnObj.newVersion)

      if (updateReturnObj.error) {
        if (updateReturnObj.error.key == 'VERSION_CHANGED') {
          store.commit('setCachedResultObjBySortIndexKey', {})
          this.$store.commit('setResultMap', {})
          this.agGrid.refreshServerSide({ purge: true })        

          error.throw(updateReturnObj.error.message, {
            type: 'info',
            msgTranslation: 'errors.VERSION_CHANGED_ON_UPDATE',
            hideReload: true,
            hideToStartPage: true,
            hideClose: false
          })
        } else if (updateReturnObj.error.key == 'RECORD_NOT_AVAILABLE') {
          store.commit('setCachedResultObjBySortIndexKey', {})
          this.$store.commit('setResultMap', {})
          this.agGrid.refreshServerSide({ purge: true })        
          
          error.throw(updateReturnObj.error.message, {
            type: 'info',
            msgTranslation: 'errors.RECORD_NOT_AVAILABLE_ON_UPDATE',
            hideToStartPage: true,
            hideReload: true,
            hideClose: false
          })
        }
      }

      return updateReturnObj
    },
    async insertRow() {
      let { dataChanged } = await dataEntry.open({
        dataInterface: this.getDataInterface('insert'),
        isCati: false,
        rowData: null,
        showPhoneCallDialer: false,
        saveAndNextButton: false,
        surveyObj: this.$store.state.view.survey,
        view: this.$store.state.view
      })

      if (dataChanged) {
        await this.refreshDatasets()
      }

      /*if (returnObj.data) {
        this.insertData(returnObj.data)
      }*/
    },
    async startRow() {
      let { dataChanged } = await dataEntry.open({
        isCati: this.isCati,
        showPhoneCallDialer: true,
        saveAndNextButton: false,
        surveyObj: this.$store.state.view.survey,
        view: this.$store.state.view,
        dataInterface: this.getDataInterface('cati')
      })

      if (dataChanged) {
        await this.refreshDatasets()
      }
    }
  },
  beforeMount() {
    let analysisSettings = this.$store.state.analysisSettings
    
    if (analysisSettings) {
      this.updateSettings(analysisSettings)
    }
  },
  created () {
    window.addEventListener('keyup', this.keyup)
  },
  beforeDestroy () {
    window.removeEventListener('keyup', this.keyup)
    
    this.destroyAgGrid()
  }
}
</script>

<style lang="scss" scoped>
  #tq-results-table {
    $animation-speed: 500ms;

    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .tq-loading-data {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;

      .md-progress-bar {
        width: 100%;
      }

      .tq-loading-data-content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
      }
    }

    .result-detail {
      display: flex;
      flex: 0 0 400px;
      min-width: 0;
      position: relative;
      transition: all 300ms;
    }

    .result-detail-close {
      flex: 0 0 0 !important;
    }

    .result-detail-opened {
      transition: all 0ms;
    }

    .result-detail-handle {
      position: absolute; 
      top: 0;
      left: -2px;
      bottom: 0;
      width: 5px;
      z-index: 1000;
      cursor: col-resize;
    }

    .dataset-button-wrapper {
      position: absolute;
      bottom: 15px;
      right: 15px;
      
      .add-button {
        margin: 0 0 0 10px;
      }

      .start-button {
        margin: 0 0 0 10px;
      }
    }

    .results-detail {
      z-index: 10;
      background: #fff;
    }

    /*.drawer-handle-container {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 5;
      flex: 0 0 0;
      color: #4e5152;
      border-left: 0 solid #aaa;
      transition: border-width 400ms;
      background: #aaa;

      .drawer-handle {
        z-index: 101;
        background: #aaa;
        top: calc(50% - 25px);
      }  
    }

    .drawer-handle {
      opacity: 1;
      $drawer-diameter: 40px;
      $drawer-radius: $drawer-diameter / 2;
      position: absolute;
      z-index: 1;
      left: -$drawer-radius;
      height: $drawer-diameter;
      width: $drawer-diameter;
      border-radius: $drawer-radius;
      margin: -$drawer-radius 0 0 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 1s;
      transition: top 300ms;
      
      .drawer-handle-inner {
        margin: 0 0 0 $drawer-radius;
        width: $drawer-radius;
        position: relative;
        left: -20px;

        i {
          color: #fff;
          transition: transform $animation-speed;
        }
      }
    }*/

  :deep {
    /* Expand cell on mouse over if not completely visible */
    .ag-theme-balham .ag-cell-value:not(.index-cell):hover {
      overflow: visible;
      z-index: 1000;
      //background: inherit;
      
      
      width: auto !important;
    }    

    /* Make header menu icon occupy no space if not displayed */
    .ag-header-cell:not(:hover) .ag-header-cell-menu-button {
      width: 0;
      overflow: hidden;
    }

    .ag-header-cell-label {
      color: #333;
    }

    .ag-root-wrapper {
      border: none;
    }

    .ag-status-bar {
      border: none;
    }
  }
}
</style>