import { render, staticRenderFns } from "./Analysis.vue?vue&type=template&id=3860644c&scoped=true"
import script from "./Analysis.vue?vue&type=script&lang=js"
export * from "./Analysis.vue?vue&type=script&lang=js"
import style0 from "./Analysis.vue?vue&type=style&index=0&id=3860644c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3860644c",
  null
  
)

export default component.exports