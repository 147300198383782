<template>
  <div>
    <v-dialog
      v-model="showSelectQuestionDialog"
      persistent
      scrollable
      width="80%"
    >
      <v-card v-if="showSelectQuestionDialog" style="height: 80vh; display: flex; flex-direction: column;">
        <v-card-title class="text-h6 white--text primary">
          Question Search
        </v-card-title>

        <v-card-text class="pa-0" style="display: flex; flex: 1 1 0;">
          <question-select :surveys="surveys" v-if="showSelectQuestionDialog" @selectQuestion="selectQuestion"></question-select>
        </v-card-text>

        <v-card-actions style="border-top: 1px solid rgb(233, 233, 233);">
          <v-btn text @click="showSelectQuestionDialog=false">{{$t('actions.cancel')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDialog"
      persistent
      no-click-animation
      scrollable
      fullscreen
    >
      <v-card v-if="showDialog" flat>
        <v-card-title class="text-h6 white--text primary">
          <span>Survey Editor</span>
          
          <v-spacer></v-spacer>

          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon>mdi-history</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(version, index) in surveyJsData.history" :key="index" @click="selectVersion(version)">
                <v-list-item-content>{{$t('texts.VERSION')}} {{version.number}} ({{dateFormat(version.lastUpdate, "yyyy-mm-dd HH:MM")}})</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
        <v-card-text style="padding: 0; display: flex; flex: 1 0 0; flex-direction: column;">
          <div ref="survey-creator" style="flex: 1 0 0; display: flex; height: 0;"></div>
          <v-card-actions style="flex: 0 0 auto; background: rgb(243, 243, 243); border-top: 1px solid rgb(214, 214, 214);">
            <v-btn text small @click="openSelectQuestionDialog">Select Question</v-btn>
          </v-card-actions>
        </v-card-text>

        <v-card-actions dense style="border-top: 1px solid rgb(233, 233, 233);">
          <v-btn text @click="cancel">{{ $t('actions.cancel') }}</v-btn>

          <v-spacer></v-spacer>

          <v-checkbox
            class="activate-draft-version-checkbox"
            v-model="activeDraftVersion"
            :label="$t('actions.activate_version')"
          ></v-checkbox>

          <v-btn color="primary" @click="save">{{ $t('actions.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { SurveyCreator } from "survey-creator-knockout"
import "survey-core/survey.i18n"
import * as SurveyKnockoutUi from "survey-knockout-ui"

import api from '@/services/api'
import dateFormat from 'dateformat'
import surveyJsUtil from '@/services/surveyjs/util'

export default {
  name: 'surveyjs-creator-form',
  data: () => ({
    activeDraftVersion: false,
    bulkWoenennQuestionVariables: null,
    creator: null,
    dateFormat: dateFormat,
    promiseResolve: null,
    question: null,
    questionMap: null,
    showDialog: false,
    showSelectQuestionDialog: false,
    surveyDefinition: null,
    surveyJsData: null,
    version: null
  }),
  props: {
    dialogObj: Object
  },
  methods: {
    cancel() {
      this.close()

      this.promiseResolve({
        cancel: true
      })
    },

    close() {
      this.showDialog = false
    },

    async initDialog() {
      let surveys = (await api.call('getSurveys')).data
      
      this.surveys = surveys

      // get the questions map for duplicate questions
      let questionMap = {}

      // need to unregister functions so function like "lookup"
      // are not executed
      surveyJsUtil.unregisterFunctions()

      for (let i = 0; i < surveys.length; ++i) {
        // wrapping in async function so the loading spinner is not frozen
        await (new Promise(resolve => {
          let survey = surveys[i]
          
          let surveyModel = new SurveyKnockoutUi.Model(survey.surveyDefinition)
          
          let questions = surveyModel.getAllQuestions()

          for (let j = 0; j < questions.length; ++j) {
            let question = questions[j]
            let questionSurveys = questionMap[question.name]

            if (!questionSurveys) {
              questionMap[question.name] = questionSurveys = []
            }
            
            if (!questionSurveys.find(questionSurvey => questionSurvey.name === survey.name)) {
              questionSurveys.push(survey)
            }
          }

          surveyModel.dispose()

          window.setTimeout(resolve, 20)
        }))
      }

      surveyJsUtil.registerFunctions()

      this.questionMap = questionMap

      let creator = this.creator = new SurveyCreator({
        isAutoSave: true,
        pageEditMode: "bypage",
        /*questionTypes: [
          "boolean",
          "checkbox",
          "comment",
          "dropdown",
          "html-rendered",
          "matrix",
          "matrixdynamic",
          "paneldynamic",
          "radiogroup",
          "ranking",
          "rating",
          "text"
        ],*/
        showJSONEditorTab: true,
        showLogicTab: true,
        showTranslationTab: true,
        //showThemeTab: true
      })

      creator.onDefineElementMenuItems.add(function (editor, options) {
        if (!options.obj.isPage) {
          // Do not show required as menu items for questions in survey creator
          ['isrequired'].forEach(removeId => {
            let index = options.items.findIndex(item => item.id == removeId)

            index >= 0 && options.items.splice(index, 1)
          })
        }
      })

      // Definition has changed in issue #861

      creator.JSON = this.surveyDefinition
      
      creator.saveSurveyFunc = (saveNo, callback) => {
        this.surveyDefinition = creator.JSON

        callback(saveNo, true)
      }

      let woenennQuestionVariables = (await api.call('getQuestionVariables')).data

      creator._widgetInterface = {
        question: this.question,
        questionMap: this.questionMap,
        surveys: surveys,
        woenennQuestionVariables: woenennQuestionVariables,
        bulkWoenennQuestionVariables: this.bulkWoenennQuestionVariables
      }

      creator.render(this.$refs['survey-creator'])
    },

    openSelectQuestionDialog() {
      this.showSelectQuestionDialog = true
    },

    async save() {
      this.close()

      this.surveyJsData.draft.surveyDefinition = this.surveyDefinition

      if (this.activeDraftVersion) {
        this.surveyJsData.production.surveyDefinition = this.surveyDefinition
      }

      let user = this.$store.state.user
      let history = this.surveyJsData.history

      history.unshift({
        activated: this.activeDraftVersion,
        number: history.length + 1,
        lastUpdate: new Date(),
        user: {
          name: user.name,
          sub: user.sub
        },
        surveyDefinition: this.surveyDefinition
      })

      // Limit the history to 5 entries
    if (history.length > 5) {
      history.pop()
    }

      this.promiseResolve({
        cancel: false,
        surveyJsData: this.surveyJsData,
        bulkWoenennQuestionVariables: this.bulkWoenennQuestionVariables
      })
    },

    selectQuestion(question) {
      let editingSurvey = this.creator.survey

      let page = editingSurvey.activePage
      
      if (!page) {
        if (editingSurvey.pageCount == 0) {
          // if there is no active page take the first page
          page = editingSurvey.addNewPage()
        } else {
          // create new page if there is no page
          page = editingSurvey.pages[0]
        }
      }
      
      page.addElement(question.clone())

      this.showSelectQuestionDialog = false
    },

    selectVersion(version) {
      this.creator.JSON = version.surveyDefinition
    }
  },

  async mounted() {
    this.$store.dispatch('setLoading', true)

    this.showDialog = true
    this.promiseResolve = this.dialogObj.resolve || function() {}
    
    let options = this.dialogObj.options

    let surveyJsData = options.surveyJsData

    // draft, production and history is new with issue #861
    if (!surveyJsData) {
      surveyJsData = {
        draft: {
          surveyDefinition: {}
        },
        production: {
          surveyDefinition: {}
        },
        history: []
      }
    } else if (!surveyJsData.draft) {
      surveyJsData = {
        draft: {
          surveyDefinition: surveyJsData
        },
        production: {
          surveyDefinition: surveyJsData
        },
        history: []
      }
    }

    this.surveyJsData = surveyJsData
    this.surveyDefinition = this.surveyJsData.draft.surveyDefinition

    this.question = options.question
    this.bulkWoenennQuestionVariables = options.bulkWoenennQuestionVariables
    
    await this.$nextTick()

    await this.initDialog()

    this.$store.dispatch('setLoading', false)
  },

  destroyed() {
    this.creator.dispose()
  }
  
}
</script>

<style lang="scss" scoped>
  .activate-draft-version-checkbox {
    margin: 0 20px 0 0;

    :deep {
      .v-messages {
        display: none;
      }
    }
  }

  :deep {
    // TEMP: Layout fixes
    .spg-question__content {
      position: relative;
    }

    .sv_q_dropdown_clean-button {
      display: none;
    }
  }
</style>
