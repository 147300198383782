export default {
  actions: {
    activate_version: "Version aktivieren",
    add_bookmark: "Lesezeichen hinzufügen",
    add_color: "Farbe hinzufügen",
    apply: "Anwenden",
    back: 'Zurück',
    bookmark_link: 'Lesezeichen Link',
    call: 'Anrufen',
    cancel: "Abbrechen",
    change: "Ändern",
    change_color: "Farbe ändern",
    change_section: "Bereich wechseln",
	  close: "Schließen",
    complete: "Abschießen",
    complete_interview: "Interview Abschließen",
    confirm: "Bestätigen",
    continue: 'Weiter',
    delete: 'Löschen',
    duplicate: "Duplizieren",
    edit: "Bearbeiten",
    export: "Export",
    hang_up: "Auflegen",
    load_data: "Datensatz laden",
    load_section: "Bereich laden",
    log_out: "Abmelden",
    login: 'Anmelden',
    move_down: 'Nach unten',
    move_up: 'Nach oben',
    next: 'Weiter',
    ok: 'Ok',
    open: 'Öffnen',
    previous: 'Zurück',
    refresh: 'Aktualisieren',
    refresh_data: "Datensatz aktualisieren",
    reload: "Neu laden",
		rename: "Umbenennen",
    reset: "Zurücksetzen",
    save: "Speichern",
    save_and_close: "Speichern und schließen",
    save_and_next: "Speichern und Nächster",
    save_draft_version: "Vorversion speichern",
    select_database: "Datenbank auswählen",
    send: "Senden",
    show: "Anzeigen",
    start_analysis: "Analyse Starten",
    stop_interview: "Interview Verlassen",
    to_start_page: "Zur Startseite wechseln",
    update: "Aktualisieren"
	},
	errors: {
    APPLICATION_ERROR: 'Programmfehler',
    AUTHENTICATION_ERROR: 'Anmeldefehler',
    DATABASE_ERROR: 'Datenbankfehler',
    EMAIL_ERROR: 'Email Fehler',
    NO_ACCESS_RIGHTS: 'Sie haben keine Zugriffsberechtigungen um auf die Daten zuzugreifen.',
    RECORD_NOT_AVAILABLE_ON_UPDATE: 'Dieser Datensatz wurde zwischenzeitlich gelöscht und die Eingaben konnten daher nicht gespeichert werden.',
		RUNTIME_ERROR: 'Fehler',
    RUNTIME_INFO: 'Info',
    RUNTIME_WARNING: 'Warnung',
    SESSION_EXPIRED: 'Die Sitzung ist abgelaufen. Bitte neu anmelden.',
		SERVER_COMMUICATION_ERROR: 'Fehler',
    SERVER_COMMUICATION_INFO: 'Info',
    SERVER_COMMUICATION_WARNING: 'Warnung',
    TIMEOUT_KEY: 'Datensatz in Bearbeitung',
    UNABLE_TO_CREATE_PDF: 'PDF kann nicht generiert werden',
    UNEXPECTED_ERROR: 'Wir entschuldigen uns für die Unannehmlichkeiten. Es ist ein Fehler im Programm aufgetreten. Unser Team wurde benachrichtigt und arbeitet aktiv daran, das Problem zu beheben.',
    VERSION_CHANGED_ON_UPDATE: 'Dieser Datensatz wurde zwischenzeitlich geändert und die Eingaben konnten daher nicht gespeichert werden. Bitte wiederholen Sie Ihre Eingaben wenn erforderlich.'
	},
  headers: {
    edit: 'Bearbeiten',
    insert: 'Einfügen'
  },
  labels: {
    absolute: "Absolut (A)",
    all: "Alle",
    all_items: "Alle Einträge",
    analysis: "Analyse",
    apply_filter: 'Filter anwenden',
    available: "Verfügbar",
    basis: "Basis (N)",
    charts: "Charts",
    clipboard: "Zwischenablage",
    data: "Datensätze",
    datasets: "Datensätze",
    decimal_separator: "Dezimaltrennzeichen",
    decimal_separator_dot: "Punkt",
    decimal_separator_comma: "Komma",    
    description: "Beschreibung",
    examples_of_values: "Beispielwert",
    f100: "F/100 ungewichted (U)",
    f100Weighted: "F/100 gewichtet (G)",
    file: "Datei",
    filetype: "Dateityp",
    filter: "Filter",
    group: "Gruppe",
    note: "Hinweis",
    options: "Options",
    original: "Original",
    row: "Zeile",
    search: "Suche",
    select_view: "Ansicht wählen...",
    selected: "Ausgewählt",
    syntax: "Syntax",
    table: "Tabelle",
    tabulation: "Tabellen",
    total: "Total",
    values: "Werte",
    variables: "Variablen",
    view: "Ansicht"
  },
  progress: {
    exporting_datasets: 'Datensätze exportieren'
  },
  nav: {
    analysis: "Analyse",
    home: "Startseite"
  },
  notifications: {
    INVALID_FORMAT_DATE: "Eingabe unzulässig: Datumsformat ist JJJJ-MM-TT",
    INVALID_FORMAT_NUMBER: "Eingabe unzulässig: Keine Zahl"
  },
  subnav: {
    business_terms: "AGB",
    contact: "Kontakt",
    legal_notice: "Impressum",
    privacy_policy: "Datenschutzhinweise",
    website: "TEMA-Q Webseite"
  },
  texts: {
    ADDITIONAL_FILTER___: "Zusätzlicher Filter von Drilldown aktiv",
    ANALYSIS_LINK: "Link zur Auswertung",
    APPLICATION_ERROR: 'Anwendungsfehler',
    APPLY_CURRENT_FILTER: 'Apply current filter',
    APPLY_CURRENT_ANALYSIS: 'Derzeitige Analyse übernehmen',
    APPLY_FILTER: "Filter übernehmen",
    AUDIO: 'Audio',
    AUDIO_PLAYBACK_SPEED: 'Wiedergabegeschwindigkeit',
    AUDIO_VOLUME: 'Lautstärke',
    BACK: 'Zurück',
    BOOKMARK_NAME: 'Lesezeichen Name',
    BOOKMARK_LINK: 'Lezezeichen Link',
		BOOKMARKS: 'Lesezeichen',
    CALL: 'Anruf',
    CALL_NUMBER: 'Telefonnummer',
    CALL_STATE_BUSY: 'Besetzt',
    CALL_STATE_CALLING: 'Anruf',
    CALL_STATE_COMPLETED: 'Aufgelegt',
    CALL_STATE_FAILED: 'Anruf fehlgeschlagen',
    CALL_STATE_RINGING: 'Freizeichen',
    CALL_STATE_IN_PROGRESS: 'Verbunden',
    CHANGED_FILTERS: "Geänderte Filter",
    CHANGED_FILTERS_AND_SETTINGS: "Geänderte Filter und Einstellungen",
    CHANGED_SETTINGS: "Geänderte Einstellungen",
    COLUMN_MENU_ALL_SAME_SIZE: "Spaltenbreite für alle Spalten übernehmen",
    COLUMN_MENU_AUTOSIZE_ALL: "Alle Spaltenbreiten automatisch anpassen",
    COLUMN_MENU_AUTOSIZE_CURRENT: "Aktuelle Spaltenbreiten automatisch anpassen",
    COLUMN_MENU_MINIMIZE_ALL: "Alle Spaltenbreiten minimieren",
    COLUMN_MENU_EXPAND_ALL: "Alle Zeilen aufklappen",
    COLUMN_MENU_COLLAPSE_ALL: "Alle Zeilen zuklappen",
    COMMENT: "Kommentar",
    CONFIRM_DELETE_FOLDER: "Löschen des Ordners bestätigen",
    CONFIRM_DELETE_BOOKMARK: "Löschen des Lesezeichen bestätigen",    
    COPY_LINK_TO_CLIPBOARD: "Link in die Zwischenablage kopieren",
    CREATE_BOOKMARK: "Lesezeichen erstellen",
    CREATE_FOLDER: "Ordner erstellen",
    CREATE_LINK: "Link generieren",
    DELETE_NUMBER: "Nummer löschen",
    DRAFT: "Entwurf",
    EDIT_BOOKMARK: "Lesezeichen bearbeiten",
    EDIT_COMMENT: "Kommentar bearbeiten",
    EDIT_FOLDER: "Ordner bearbeiten",
    EDIT_NUMBER: "Telefonnummer bearbeiten",
    email: {
      content: "Inhalt",
      description: "Beschreibung",
      email_address: "Email Adresse",
      email_template: "Email Vorlage",
      email_will_be_sent___: "Email wird an {count} Empfänger gesendet.",
      emails_sent: "Emails gesendet",
      finished: "Fertig",
      no_preview: "Keine Vorschau vorhanden",
      preview: "Vorschau",
      ready_to_send: "Fertig zum Versenden",
      recipient: "Empfänger",
      recipients_ready: "Empfänger ({length} von {count} fertig)",
      send_emails: "Emails verschicken",
      send_test_email: "Testemail verschicken",
      sending: "wird gesendet",
      status: "Status",
      subject: "Betreff",
      text: "Text",
      to: "An",
      attachments: "Anhänge"
    },
    ERROR_ACTIVATE_THIRD_PARTY_COOKIES: "Drittanbieter-Cookies müssen aktiviert sein.",
    ERROR_AUTH_FAILED: "Bei der Authentifizierung ist ein Fehler aufgetreten. Bitte laden Sie die Seite erneut und versuchen Sie es noch einmal.",
    ERROR_ID: 'Fehler ID',
    ERROR_NO_SELECTION_FOR: "Keine Auswähl für ",
    ERROR_TOO_FEW_ITEMS_SELECTED: "Zu wenige Elemente ausgewählt, Minimum ",
    ERROR_TOO_MANY_ITEMS_SELECTED: "Zu viele Elemente ausgewählt, Maximum ",
    ERROR_WRONG_CREDENTIALS: "Falscher Benutzername oder falsches Passwort.",
    EXPORT: "Exportieren",
    EXPORT_ALL_DATA: "Alle Daten",
    EXPORT_LINK: "Link exportieren",
    EXPORT_LIMIT_NOTE: "ACHTUNG: Sie exportieren mehr als 10.000 Einträge. Sie können fortfahren, aber der Export wird automatisch auf 10.000 Einträge begrenzt.",
    EXPORT_ONLY_VISIBLE_DATA: "Nur sichtbare Daten",
    EXPORT_SCOPE: "Bereich",
    EXPORT_PAGE: "Seite",
    FILTER: "Filter",
    FILTER_ARRAY_OF_STRINGS_NOTE_1: "Textwerte können mit Kommas kombiniert werden",
    FILTER_ARRAY_OF_STRINGS_NOTE_2: "Es werden dann alle Ergebnisse angezeigt, die diese Werte erfüllen",
    FILTER_ARRAY_OF_STRINGS_PLACEHOLDER: "Hier Textwerte eintragen",
    FILTER_CHANGE_AND_RELOAD_MESSAGE: "Sie können Ihre gesetzten Filter ändern und den Datensatz aktualisieren.",
    FILTER_DATE_NOTE_1: "Zeiträume werden mit '-' getrennt",
    FILTER_DATE_NOTE_2: "Zeiträume verstehen sich immer einschließlich des Anfangs- und Enddatums",
    FILTER_DATE_NOTE_3: "Zeiträume können mit Kommas kombiniert werden",
    FILTER_DATE_NOTE_4: "Leere Werte können mit $null bzw. $notnull ausgewählt/ausgeschlossen werden",
    FILTER_DATE_NOTE_5: "Syntax der Datumsangabe: JJJJ/MM/TT oder JJJJ/MM oder JJJJ",
    FILTER_DATE_NOTE_6: "Datumsvariablen: today, thisweek, thismonth, thisyear",
    FILTER_DATE_NOTE_7: "Optionale Offsets (Anzahl+Einheit): today(-4d), 2013/07/18(+2w)",    
    FILTER_DATE_PLACEHOLDER: "Hier Datum oder Zeitraum eintragen",
    FILTER_DIALOG_INFO: {
      LIST_ENTRY_1_a: "Führt eine Volltextsuche über das \"",
      LIST_ENTRY_1_b: "\" Verbatim aus.",
      LIST_ENTRY_2: "Für große Datensätze kann es zu längeren Verzögerungen in der Volltextsuche kommen.",
      LIST_ENTRY_3: "Das Einschränken der Datensätze mit zusätzlichen Filtern verbessert die Suchgeschwindigkeit."
    },
    FILTER_DIALOG_SYNTAX: {
      LIST_ENTRY_1: "Und-Verknüpfung als default: Apfel Elstar",
      LIST_ENTRY_2: "Oder-Verknüpfung durch voranstellen von |: |Elstar Gala",
      LIST_ENTRY_3: "Negieren eines Ausdrucks durch !: !Apfel",
      LIST_ENTRY_4: "Ganzes Wort und nicht nur Teil eines Worts matchen durch ^: ^Apfel",
      LIST_ENTRY_5: "Mehrere Wörter matchen durch Anführungszeichen ('): 'Granny Smith'",
      LIST_ENTRY_6: "Leere Werte können mit $null bzw. $notnull ausgewählt/ausgeschlossen werden"
    },
    FILTER_NUMBER_NOTE_1: "Intervalle werden mit '-' getrennt",
    FILTER_NUMBER_NOTE_2: "Intervalle verstehen sich immer einschließlich des Anfangs- und Endwerts",
    FILTER_NUMBER_NOTE_3: "Intervalle können mit Kommas kombiniert werden",
    FILTER_NUMBER_NOTE_4: "Leere Werte können mit $null bzw. $notnull ausgewählt/ausgeschlossen werden",
    FILTER_NUMBER_PLACEHOLDER: "Hier Wert oder Interval eintragen",
    FILTER_REFRESH_TO_APPLY_MESSAGE: "Sie können Ihre gesetzten Filter ändern und den Datensatz aktualisieren.",
		FILTER_SELECT_FILTER_MESSAGE: "Legen Sie über einen Filter fest, welche Daten Sie analysieren möchten.",
    FILTER_STRING_PLACEHOLDER: "Hier freien Text eintragen",
    FOLDER_NAME: "Ordner Name",
    FOOTER_LEGAL_1: "WOENENN",
    FOOTER_LEGAL_2: "ist eine Anwendung von TEMA-Q. Alle Rechte vorbehalten.",
    HOME_WELCOME: "Willkommen bei WOENENN",
    LAST_ANALYSIS: "Letzte Auswertung",
    LOADING: "Daten werden geladen. Bitte warten.",
    LOGIN_SUCCESS: "Anmeldung erfolgreich!",
    NEW_NUMBER: "Neue Telefonnummer",
    NO_DATA_LOADED: "Kein Datensatz geladen",
    NO_DATA_FOUND: 'Keine übereinstimmenden Datensätze gefunden',
    NO_ENTRIES: 'Keine Einträge',
    NO_SAVED_BOOKMARKS: "Keine gepeicherten Lesezeichen",
    PHONE_NUMBERS: 'Telefonnummern',
    PUBLIC_BOOKMARKS: "Öffentliche Lesezeichen",
    PRIVATE_BOOKMARKS: "Private Lesezeichen",
    REFRESH: "Refresh",
    SAVE_WITH_FILTER: "Mit Filtern speichern",
    SECTIONS: "Bereiche",
    SEND_EMAIL: "Email verschicken",
    SESSION_EXPIRED: 'Sitzung abgelaufen',
		SETTINGS: "",
    SETTINGS_TABULATION: "Einstellungen Tabellen",
    SETTINGS_DATASETS: "Einstellungen Datensätze",
    SETTINGS_CHART: "Einstellungen Charts",
    SHORT_URL: "Kurze URL",
    SHOW_GLOBAL_BOOKMARKS: "Globale Lesezeichen anzeigen",
    SHOW_OTHER_SECTIONS: "Andere Bereiche anzeigen",
    STATUS: "Status",
    UPDATE_BOOKMARK: "Lesezeichen ändern",
    VERSION: "Version",
    YOUR_LAST_ANALYSIS___: "Ihre letzte Auswertung wurde gespeichert.",
    YOUR_SESSION_HAS___: "Ihre Sitzung ist abgelaufen. Zum fortfahren bitte neu anmelden."
	},
	tooltips: {
    ADD_DATASET: 'Datensatz hinzufügen',
    ADD_NUMBER: "Nummer hinzufügen",
    ADD_VARIABLE: 'Variable hinzufügen',
    BOOKMARKS: 'Lesezeichen',
    BOOKMARKS_PRIVATE: 'Allgemeine Lesezeichen',
    CLOSE_SIDEBAR: 'Seitenleiste schließen',
    DELETE_ALL_FILTER: 'Alle Filter löschen',
    EDIT_COMMENT: "Kommentar bearbeiten",
    FILTER: 'Filter',
    FORMAT_CODE: 'Code formatieren',
    HISTORY: 'Verlauf',
    NAVIGATE_BACK: 'Zurück navigieren',
    NAVIGATE_FORWARD: 'Vorwärts navigieren',
    OPEN_IN_NEW_WINDOW: "In neuem Fenster öffnen",
    OPEN_SIDEBAR: 'Seitenleiste öffnen',
    PUBLIC_BOOKMARKS: 'Eigene Lesezeichen',
    RESET_FILTERS: "Filter zurücksetzen",
    SECTIONS: "Bereiche",
    SET_TO_DEFAULT: "Auf Defaultwert setzen",
    SETTINGS: 'Einstellungen',
    SHOW_CODE: 'Code anzeigen',
    SHOW_PREVIEW: 'Vorschau anzeigen',
    START: 'Start',
    VIEW_DATASETS: 'Datensätze Ansicht',
    VIEW_TABULATION: 'Tabellen Ansicht',
    VIEW_CHARTS: 'Charts Ansicht'
	},
  titles: {
    confirm: 'Bestätigen'
  }
}